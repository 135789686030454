/*------------------------------------*\
	# ALIGNMENT
\*------------------------------------*/

/** 
 * This is generally only used for WYSIWYG Styling for the users input
 */

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	@include center-block;
}