/*------------------------------------*\
	# TABLES
\*------------------------------------*/

table {
	margin: 0;
	width: 100%;
}

th {
	font-weight: bold;
}
