.iframe {
  .iframe-embed {
    width: 1px;
    min-width: 100%;
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #0f0;
    }
    &::-webkit-scrollbar-thumb {
      background: #00f;
    }
  }
}
