
// Sorting - using :before (asc) and :after (desc) with UTF8 characters
// Note that each class (e.g. dt-orderable-asc) can be applied without
// any of the others also being applied.
table.dataTable thead {
	> tr > th,
	> tr > td {
		&:active {
			outline: none;
		}

		&.dt-orderable-asc,
		&.dt-ordering-asc {
			span.dt-column-order:before {
				position: absolute;
				display: block;
				bottom: 50%;
				content: "\25B2"; // up arrow - ascending
				content: "\25B2" / "";
			}
		}

		&.dt-orderable-desc,
		&.dt-ordering-desc {
			span.dt-column-order:after {
				position: absolute;
				display: block;
				top: 50%;
				content: "\25BC"; // down arrow - descending
				content: "\25BC" / "";
			}
		}

		// Common to all combinations
		&.dt-orderable-asc,
		&.dt-orderable-desc,
		&.dt-ordering-asc,
		&.dt-ordering-desc {
			position: relative;
			padding-right: 30px;

			span.dt-column-order {
				position: absolute;
				right: 12px;
				top: 0;
				bottom: 0;
				width: 12px;

				&:before,
				&:after {
					left: 0;
					opacity: 0.125;
					line-height: 9px;
					font-size: 0.8em;
				}
			}
		}

		&.dt-orderable-asc,
		&.dt-orderable-desc {
			cursor: pointer;

			&:hover {
				outline: 2px solid rgba(0, 0, 0, 0.05);
				outline-offset: -2px;
			}
		}

		&.dt-ordering-asc span.dt-column-order:before,
		&.dt-ordering-desc span.dt-column-order:after {
			opacity: 0.6;
		}

		&.sorting_desc_disabled span.dt-column-order:after,
		&.sorting_asc_disabled span.dt-column-order:before {
			display: none;
		}

		&:active {
			outline: none;
		}
	}
}

div.dt-scroll-body > table.dataTable > thead {
	> tr > th,
	> tr > td {
		overflow: hidden;
	}
}

:root.dark,
:root[data-bs-theme="dark"] {
	table.dataTable thead {
		> tr > th,
		> tr > td {
			&.dt-orderable-asc,
			&.dt-orderable-desc {
				&:hover {
					outline: 2px solid rgba(255, 255, 255, 0.05);
				}
			}
		}
	}
}