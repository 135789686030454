#searchwp_live_search_results_66d15ba2bf56e {

  .searchwp-live-search-results-container {
    padding: 5px 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .searchwp-live-search-results-showing {
    margin-left: -16px;
    margin-top: 15px;
  }
  .searchwp-live-search-result--title {
    a {
      font-size: 14px;
      display: inline;
      white-space: normal;
      line-height: 1.25;
    }
  }

  .searchwp-live-search-result {
    padding: 5px 10px;

    img,
    svg {
      aspect-ratio: 16 / 7;
      object-fit: cover;
      object-position: center;
    }
  }
}
