.btn {
  &.btn-link {
    &.btn-link-chevron {
      color: $primary;
      outline: 0;
    }
  }
}

.bg-primary {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-secondary {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-success {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-info {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-warning {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-danger {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-light {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($dark, 95%);
        &::after {
          background: rgba($dark, 75%);
        }
        >svg switch {
          fill: $dark !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($dark, 90%);
          &::after {
            background: rgba($dark, 90%);
          }
        }
      }
    }
  }
}

.bg-dark {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-accent {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-alert {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-green {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($white, 95%);
        &::after {
          background: rgba($white, 75%);
        }
        >svg switch {
          fill: $white !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($white, 90%);
          &::after {
            background: rgba($white, 90%);
          }
        }
      }
    }
  }
}

.bg-yellow {
  .btn {
    &.btn-link {
      &.btn-link-chevron {
        color: rgba($dark, 95%);
        &::after {
          background: rgba($dark, 75%);
        }
        >svg switch {
          fill: $dark !important;
        }
        &:hover,
        &:active,
        &:focus {
          background: none;
          color: rgba($dark, 90%);
          &::after {
            background: rgba($dark, 90%);
          }
        }
      }
    }
  }
}
