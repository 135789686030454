.icon-box-links {
  svg {
    width: 1.75rem;
    path,
    switch,
    g {
      fill: #B4DEDE !important;
    }
  }
  .card-title {
    padding: 0 0.375rem 0.375rem 0.375rem;
    border-bottom: 2px solid #B4DEDE;
  }
}

/* = ALT - HOME HEADER
----------------------------------------------- */

.icon-box-links--alt {
  margin-bottom: var(--spacer-11);

  @include bp($tablet-p) {
    margin-bottom: 0;
    margin-top: var(--spacer-9);
  }

  @include bp($tablet) {
    margin-top: -75px;
  }

}

.icon-box-links--alt svg {
  path,
  switch,
  g {
    fill: #0C9798 !important;
  }
}

.icon-box-links--alt .icon-box-links__container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
  max-width: 540px;

  @include bp($phone-l) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }

  @include bp($tablet) {
    display: flex;
    justify-content: center;
    max-width: none;
  }

  @include bp($desktop) {
    gap: 45px;
  }

}

.icon-box-links--alt .card-title {
  border-bottom: none;
}

.icon-box-links--alt .card-info-box {
  flex: 1 0 auto;
  align-self: stretch;
  aspect-ratio: 1 / 1;
  width: 100%;

  @include bp($tablet) {
    flex: 0 0 150px;
  }

}



.icon-box-links--alt .card-info-box__icon {
  flex: 0 0 auto; // Dont shrink, dont grow, stay the same size
  width: 20%;
  height: 20%;
  min-width: 30px;
  min-height: 30px;

  display: flex;
  align-items: start;
  justify-content: center;
}

.icon-box-links--alt .card-info-box__icon svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.footer-info-boxes__row {
  .card-info-box{
    transition: all 0.125s $transition-main;
    &:hover,
    &:active,
    &:focus {
      background-color: hsl(346 68% 98% / 1) !important;
    }
  }
}

.page-header {
  .card-info-box.bg-primary-white {
    transition: all 0.125s $transition-main;
    &:hover,
    &:active,
    &:focus {
      background-color: #eef6f6 !important;
    }
  }
}
