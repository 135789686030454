.fw-two-col-images {
  .btn {
    &.btn-outline-dark {
      border-color: $primary !important;
      color: $primary-darker !important;
      background: #cde4e3;
    }
    &.btn-outline-primary {
      border-color: $primary !important;
      color: $primary-darker !important;
      background: #cde4e3;
    }
    &.btn-outline-accent {
      border-color: $accent !important;
      color: $accent-darker !important;
      background: #bbc3db;
    }
    &.btn-outline-secondary {
      border-color: $secondary !important;
      color: $secondary-darker !important;
      background: #c5c4b3;
    }
  }
}
