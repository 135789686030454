.accordion {
  .accordion-button {
    background-color: $accent-lighter;
    font-weight: 600;
    @include font-size(18);
    letter-spacing: 0.03em;
    line-height: 1.5;
    &.collapsed {
      background: $primary-lighter;
    }
  }
  .accordion-body {
    table {
      margin-bottom: 0.5rem;
      tr {
        border-bottom: 1px solid #B4D8E6;
        line-height: 1.325;
      }
      td,
      th {
        @include font-size(14);
        line-height: 1.325;
        letter-spacing: 0.02em;
        color: $gray-900;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        strong, b {
          font-weight: 600;
        }
      }
    }
  }
}
