@use "sass:math";

.alert {
  &.alert-topbar {
    --bs-alert-padding-y: 0.6875rem;
    @include bp($nv) {
      --bs-alert-padding-y: 1.5875rem;
    }
    .btn-close {
      padding: 0.6875rem 1.875rem;
      @include bp($nv) {
        padding: 1.5875rem 1.875rem;
      }
    }
    border: 0;
    border-bottom: 1px solid #085b5c !important;
    a {
      svg {
        display: inline-block;
        margin-left: 10px;
        width: 14px;
      }
    }

    .alert__link--wrapper {
      margin-right : 1.5rem;
      @include bp($xl) {
        margin-right: 7.5rem;
      }
    }
    .alert__icon--wrapper {
      svg {
        margin-right: 1.5rem;
        @include bp($lg) {
          margin-right: 4.5rem;
        }
      }
    }
    .alert__heading--wrapper {
      @include font-size(16);
      @include bp($nv) {
        @include font-size(22);
      }
      font-weight: 700;
      letter-spacing: 0.03em;
      line-height: math.div(26, 22);
      text-align: left;
    }
    .alert__content--wrapper {
      @include font-size(16);
      line-height: math.div(22, 16);
      letter-spacing: 0.02em;
      font-weight: 400;
      text-align: center;
    }
    .alert__link--wrapper {
      @include font-size(14);
      font-weight: 700;
      letter-spacing: 0.1em;
      line-height: math.div(15, 14);
      text-transform: uppercase;
      a {
        transition: all 0.125s $transition-main;
        padding-bottom: 5px;
        border-bottom: 3px solid rgba(#fff, 0.2);
        &:hover,
        &:active,
        &:focus {
          border-bottom-color: rgba(#fff, 1);
        }
      }
    }
  }
}
