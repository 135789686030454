/*------------------------------------*\
	# MEDIA MASTER
\*------------------------------------*/

/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
	max-width: 100%;
}

@import "captions";
