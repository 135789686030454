.c-fp-social__link {
  display: block;
  height: 100%;
  position: relative;
}

.c-fp-social__icon {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 3;
}

.c-fp-social__img {
  aspect-ratio: 1 / 1;
}
