@use "sass:math";

#main .file-download-table {
  .pagination {
    --bs-pagination-active-bg: #{""+$primary};
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: #0c9798;
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #ebf1f8;
    --bs-pagination-border-radius: 0;
    --bs-pagination-hover-color: #11d4d4;
    --bs-pagination-hover-bg: #f2f6fa;
    --bs-pagination-hover-border-color: #ebf1f8;
    --bs-pagination-focus-color: #0fb8b8;
    --bs-pagination-focus-bg: #f2f6fa;
    --bs-pagination-focus-box-shadow: 0 0 0 0.125rem rgba(78,189,229,.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0c9798;
    --bs-pagination-active-border-color: #084d4d;
    --bs-pagination-disabled-color: #9E9E9E;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #ebf1f8;
  }
  table.table.dataTable.table-striped>tbody>tr:nth-of-type(odd)>* {
    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .035);
  }
  table.table.dataTable.table-striped>tbody>tr:hover>* {
    box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .05);
  }
  #dt-search-0 {
    width: 20.5rem;
    max-width: 100%;
  }
  #parent_cats {
    width: 18rem;
    max-width: 100%;
    cursor: pointer;
  }
  table.dtr-details {
    margin-bottom: 0;
    >tr {
      >td {
        @include font-size(14);
      }
      >td:nth-child(1) {
        font-weight: 600;
        text-align: left;
      }
      >td:nth-child(2) {
        text-align: right;
      }
      &[data-dt-column="1"] {
        display: none;
      }
    }
  }
  @include bp(0 $lg) {
    .stretched-link::after {
      display: none !important;
    }
  }
}

.file-download-table__heading {
  @include font-size(18);
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1.5;
}

.file-download-table__filter {
  >button {
    letter-spacing: 0.05em;
    font-weight: 700;
    @include font-size(12);
    line-height: 1.5;
    padding: 0.5rem 1.3125rem;
  }
}

.file-download-table__td {
  @include font-size(14);
  letter-spacing: 0.02em;
  line-height: math.div(20, 14);
  font-weight: 400;
  &.file-download-table__file {
    padding: 0;
    >a {
      padding: 0.5rem;
      display: flex;
      align-items: center;
    }
  }
}

