.nav {
  .nav-item {
    color: $lighter;
    transition: color 0.2s ease-in-out;
    >a,
    .nav-link {
      color: $lighter;
      transition: color 0.2s ease-in-out;
      [href=""] {
        cursor: resize !important;
      }
      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
    }
    &.nav-item-has-children {
      >.nav-link {
        cursor: default;
      }
    }
  }
}

#backdrop {
  position: fixed;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 0;
}
