.footer-info-boxes {
  // padding-top: 136px;
  .card {
    height: 100%;
    @include bp($lg) {
      aspect-ratio: 1 / 1;
    }
  }
  .card-body {
    svg {
      width: 2rem;
      height: 2rem;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.footer-info-boxes__underline {
  padding-bottom: 0.375rem;
  border-bottom: 2px solid #f2c0cc;
  line-height: 1;
}
