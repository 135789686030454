.form-block {
  &.form-block--dark {
    .form-control {
      background-color: $dark;
      border: 2px solid $dark-item-border-color;
      color: $lighter;
      &::placeholder {
        color: $light;
      }
    }
    label,
    .animate-label>label {
      background-color: $dark;
      color: $lighter;
    }
    .gform_button.btn.btn-form-submit {
      color: $lighter;
      &:active,
      &:hover,
      &:focus {
        background: $lighter;
        color: $dark;
      }
      &:focus {
        box-shadow: 0 0 0 2px rgba($lighter, .25);
      }
    }
  }
}
