/* = BOOTSTRAP and VARIABLES
----------------------------------------------- */

@import "functions";

@import "variables-settings-master"; // Baseliness variables
@import "bootstrap-settings"; // Bootstrap Overrides (Colors above)

@import "../../../node_modules/bootstrap/scss/_variables.scss"; // Bootstrap Defaults

$svg-chevron-right-01: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMinYMin slice' viewBox='0 0 6 10' %3E%3Cswitch fill='%2386cbcb'%3E%3Cg%3E%3Cpath d='M.26 9.78a.8.8 0 0 1-.24-.56c0-.21.09-.4.24-.54L4.03 5 .23 1.35A.67.67 0 0 1 0 .8C0 .58.1.38.25.24A.71.71 0 0 1 .81 0c.21 0 .41.1.56.24l4.39 4.24a.8.8 0 0 1 .17.24.7.7 0 0 1 0 .6.8.8 0 0 1-.17.24L1.39 9.78a.76.76 0 0 1-.57.22c-.2 0-.4-.08-.56-.22z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
$svg-chevron-right-01-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMinYMin slice' viewBox='0 0 6 10' %3E%3Cswitch fill='%23FEFCF6'%3E%3Cg%3E%3Cpath d='M.26 9.78a.8.8 0 0 1-.24-.56c0-.21.09-.4.24-.54L4.03 5 .23 1.35A.67.67 0 0 1 0 .8C0 .58.1.38.25.24A.71.71 0 0 1 .81 0c.21 0 .41.1.56.24l4.39 4.24a.8.8 0 0 1 .17.24.7.7 0 0 1 0 .6.8.8 0 0 1-.17.24L1.39 9.78a.76.76 0 0 1-.57.22c-.2 0-.4-.08-.56-.22z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-bullet-01: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 2' width='10' height='2'%3E%3Cswitch fill='%2355b7b7'%3E%3Cg%3E%3Cpath d='M6 2H1a1 1 0 0 1 0-2h5a1 1 0 0 1 0 2z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
$svg-bullet-01-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 2' width='10' height='2'%3E%3Cswitch fill='%23ffffff'%3E%3Cg%3E%3Cpath d='M6 2H1a1 1 0 0 1 0-2h5a1 1 0 0 1 0 2z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-swoosh-01: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1400 214' width='1400' height='214' x='0' y='0' class='svg svg-swoosh svg-swoosh-01'%3E%3Cswitch fill='%23189c9d'%3E%3Cg%3E%3Cpath d='M.18 214H1400c-.08-.12.04-112.32-.01-118.29-85.12 22-175.47 30.66-263.44 30.66-171.66 0-346.93-33.2-518.51-65.72C397.76 18.9 186.28-20.27 0 11.74'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-swoosh-01-dark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1400 214' width='1400' height='214' x='0' y='0' class='svg svg-swoosh svg-swoosh-01'%3E%3Cswitch fill='%230b6c6c'%3E%3Cg%3E%3Cpath d='M.18 214H1400c-.08-.12.04-112.32-.01-118.29-85.12 22-175.47 30.66-263.44 30.66-171.66 0-346.93-33.2-518.51-65.72C397.76 18.9 186.28-20.27 0 11.74'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-swoosh-01-xl: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org$svg-swoosh-01/2000/svg' xml:space='preserve' viewBox='0 0 1920 214' x='0' y='0' class='svg svg-swoosh svg-swoosh-01-xl' width='1920' height='214'%3E%3Cswitch fill='%23189c9d'%3E%3Cg%3E%3Cpath d='M.25 214H1920c-.11-.12.05-112.32-.02-118.29-116.73 22-240.64 30.66-361.29 30.66-235.41 0-475.78-33.2-711.1-65.72C545.5 18.9 255.48-20.27 0 11.74'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-swoosh-01-dark-xl: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1920 214' class='svg svg-swoosh svg-swoosh-01-xl' %3E%3Cswitch fill='%230b6c6c'%3E%3Cg%3E%3Cpath d='M.25 214H1920c-.11-.12.05-112.32-.02-118.29-116.73 22-240.64 30.66-361.29 30.66-235.41 0-475.78-33.2-711.1-65.72C545.5 18.9 255.48-20.27 0 11.74'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-swoosh-01-light-xl: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1920 214' x='0' y='0' class='svg svg-swoosh svg-swoosh-01-xl' %3E%3Cswitch fill='%23189c9d'%3E%3Cg%3E%3Cpath d='M.25 214H1920c-.11-.12.05-112.32-.02-118.29-116.73 22-240.64 30.66-361.29 30.66-235.41 0-475.78-33.2-711.1-65.72C545.5 18.9 255.48-20.27 0 11.74'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-vsep-01-gray: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 81' width='1' height='81' class='svg vsep vsep-01' x='0' y='0'%3E%3Cswitch%3E%3Cg%3E%3Cpath fill='none' stroke='%23aca593' stroke-dasharray='5' stroke-linecap='round' d='M.5 76.5V.5'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-vsep-02-gray: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 1 10' viewBox='0 0 1 10'%3E%3Cswitch%3E%3Cg%3E%3Cpath d='M.5 6a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 1 0v5a.5.5 0 0 1-.5.5z' style='fill:%23d4d0c4'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-hsep-01-gray: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 2'%3E%3Cswitch fill='%23D4D0C4'%3E%3Cg%3E%3Cpath d='M5.14 2H.86C.38 2 0 1.55 0 1s.38-1 .86-1h4.28C5.62 0 6 .45 6 1s-.38 1-.86 1z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-hsep-01-primary: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 2'%3E%3Cswitch fill='%230D8080'%3E%3Cg%3E%3Cpath d='M5.14 2H.86C.38 2 0 1.55 0 1s.38-1 .86-1h4.28C5.62 0 6 .45 6 1s-.38 1-.86 1z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-hsep-02-gray: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 10 1' viewBox='0 0 10 1'%3E%3Cswitch%3E%3Cg%3E%3Cpath d='M5.5 1h-5a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1z' style='fill:%23d4d0c4'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
$svg-hsep-02-dark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 10 1' viewBox='0 0 10 1'%3E%3Cswitch%3E%3Cg%3E%3Cpath d='M5.5 1h-5a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1z' style='fill:%23084d4d'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-hsep-02-primary: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 10 1' viewBox='0 0 10 1'%3E%3Cswitch%3E%3Cg%3E%3Cpath d='M5.5 1h-5a.5.5 0 1 1 0-1h5a.5.5 0 1 1 0 1z' style='fill:%230D8080'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");

$svg-hsep-03: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='1' viewBox='0 0 10 1'%3E%3Cline x1='5' transform='translate(0.5 0.5)' fill='none' stroke='%23d4d0c4' stroke-linecap='round' stroke-width='1' stroke-dasharray='5 5'/%3E%3C/svg%3E");

$svg-download-01: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.165 13.165' class='svg svg-download' width='13' height='13'%3E%3Cpath fill='%230C9798' d='M6.582 9.524a.91.91 0 0 1-.309-.051.722.722 0 0 1-.267-.175L3.044 6.336a.754.754 0 0 1-.237-.576.868.868 0 0 1 .237-.576.839.839 0 0 1 .586-.257.766.766 0 0 1 .586.237l1.543 1.543V.824a.8.8 0 0 1 .237-.586.8.8 0 0 1 .586-.237.8.8 0 0 1 .586.237.8.8 0 0 1 .237.586v5.883l1.543-1.543a.766.766 0 0 1 .586-.237.839.839 0 0 1 .586.257.868.868 0 0 1 .237.576.754.754 0 0 1-.237.576L7.158 9.298a.722.722 0 0 1-.267.175.91.91 0 0 1-.309.051Zm-4.937 3.641a1.585 1.585 0 0 1-1.162-.483A1.585 1.585 0 0 1 0 11.52V9.874a.8.8 0 0 1 .237-.586.8.8 0 0 1 .586-.237.8.8 0 0 1 .586.237.8.8 0 0 1 .237.586v1.646h9.874V9.874a.8.8 0 0 1 .237-.586.8.8 0 0 1 .586-.237.8.8 0 0 1 .586.237.8.8 0 0 1 .237.586v1.646a1.585 1.585 0 0 1-.483 1.162 1.585 1.585 0 0 1-1.162.483Z'/%3E%3C/svg%3E");

$svg-dashed-box-01-primary: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230C9798' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

$svg-dashed-box-01-gray: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23d4d0c4' stroke-width='2px' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

$svg-swoosh-04-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1920 182' style='background: %23fefcf6' x='0' y='0' class='svg svg-swoosh svg-swoosh-04-xl'%3E%3Cswitch%3E%3Cg%3E%3Cpath fill='%23fbf4e4' d='M1920 182v-9.06c-116.37-1.46-276.16-29.2-460.98-62.22C1232.48 70.22 958.88 21.3 659.1 5.32l-6.42-.34C445.28-5.72 225.45-.57.01 34.1L0 182h1920z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
