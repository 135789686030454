/*------------------------------------*\
	# BUTTONS
\*------------------------------------*/


/* = FORM BUTTONS
----------------------------------------------- */

@use "sass:math";

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  // background: color__(primary);
  // border: none;
  cursor: pointer;
  /* Improves usability and consistency of cursor style between image-type 'input' and others */
  -webkit-appearance: button;
  /* Corrects inability to style clickable 'input' types in iOS */
}

.btn {
  &.btn-link-chevron {
    @include font-size(16);
    padding: 0.25rem 0.25rem 0.25rem 0.125rem;
    position: relative;
    line-height: math.div(24, 16);
    letter-spacing: 0.03em;
    font-weight: 700;
    color: $primary;
    display: inline-flex;
    align-items: center;
    border: 0;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: hsl(180 85% 37% / 1);
      top: 100%;
      left: 0;
      border-radius: 2px;
      transition: all 0.2s $transition-main;
    }

    >svg {
      display: inline-block;
      margin-left: 1.5rem;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in-out;

      switch {
        fill: $primary !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $primary;
      &::after {
        background: hsl(180 85% 27% / 1);
      }
      svg {
        transform: translate3d(0.25rem, 0, 0);
      }
    }
  }

  &.btn-arrow-right {
    @include font-size(16);
    padding: 0;
    line-height: 1;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: $primary;
    display: inline-flex;
    align-items: center;
    border: 0;
    text-transform: uppercase;

    >svg {
      display: inline-block;
      margin-left: 1.5rem;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in-out;

      switch {
        fill: $primary !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $primary;

      svg {
        transform: translate3d(0.25rem, 0, 0);
      }
    }
  }


  &.btn-dotted-chevron-right {
    @include font-size(20);
    padding: 0;
    line-height: (30*0.05);
    letter-spacing: 0.03em;
    font-weight: 700;
    color: $gray;
    display: inline-flex;
    align-items: center;
    border: 0;
    outline: 0;
    box-shadow: none;
    position: relative;

    >svg {
      display: inline-block;
      margin-left: 0.75rem;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in-out;

      switch {
        fill: $gray !important;
      }
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      height: 2px;
      background-image: $svg-hsep-01-gray;
      width: 100%;
      bottom: -4px;
      opacity: 0;
      transition: all 0.125s $transition-main;
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      color: $gray;
      &::after {
        opacity: 1;
      }
      svg {
        transform: translate3d(0.25rem, 0, 0);
      }
    }
  }

  &.btn__contact-us {
    display: none;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    @include bp($lg) {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
    @include bp($nv) {
      display: block;
    }
  }
}

.btn-hover-effect {
  transition: 0.125s $transition-main;
  &:hover,
  &:active,
  &:focus {
    background-color: #99d1d124;
    color: #faf4e6;
  }
}
