/*------------------------------------*\
	# STACK

	- Previously used as .l-rhythm

	@source https://every-layout.dev/layouts/stack/
	@since baselines 2.3.0
\*------------------------------------*/

.l-stack-reset > * {
	margin: 0;
}

[class*="l-stack"] > * {
	margin-block: 0;
}

[class*="l-stack"] > * + * {
	--stack-space: var(--gap); // See _layout-settings.scss for --gap sizes
	margin-block-start: var(--stack-space, 1.5rem);
}

/* -----------------------------------------------

EXAMPLE
Add a smaller variation for entire stack.
These just adjust the CSS variable not the architecture.

.l-stack-xs > * + * {
	--stack-space: var(--gap-xs);
}

Add a smaller variation for a single item in the stack.

.stack-space-xs {
	--stack-space: var(--gap-xs);
}

OR for a single once off space change inline.

<div style="--stack-space: 10px"></div>

----------------------------------------------- */


// See $gap in _layout-settings to generate more.

@each $gap_name, $gap in $gaps {

	$gap_size: str-replace($gap_name, 'gap-', '');

	// Print all but the default gap.
	// The default gap is not caught by the str-replace
	// above and prints out .l-stack-gap
	@if $gap_size != "gap" {
		.l-stack-#{$gap_size} > * + *,
		.stack-space-#{$gap_size} {
			--stack-space: var(--#{$gap_name}, #{$gap});
		}
	}

}





/* = RELATIONSHIPS

EXAMPLE
Change the CSS variable to adjust the space!

.l-stack > .f-display-1 + f-display-2 {
	--stack-space: 0.2em;
}

----------------------------------------------- */




/* = CONTAINERS STACK
..................................*/

.l-stack-containers > * + * {
	--stack-space: 2.3125rem;
	@include bp($md) {
		--stack-space: 3.4375rem;
	}
	@include bp($lg) {
		--stack-space: 4rem;
	}
	@include bp($xl) {
		--stack-space: 4.625rem;
	}
}

// If two adjacent containers have backgrounds push them together.
.l-stack-containers > [class*="bg-"] + [class*="bg-"] {
	--stack-space: 0;
}

// If two adjacent containers have the SAME bacakground remove the padding on the second.
.l-stack-containers > [class*="bg-primary"] + [class*="bg-primary"] {
	padding-top: 0;
}

.l-stack-containers > .svg-swoosh-id-ecf4cb24 + .two-col-image-left {
	margin-top: 0;
	background: $light;
	padding-top: var(--stack-space);
}

.l-stack-containers > .svg-swoosh-id-ecf4cb24 + .two-col-image-left + .callout-block {
	margin-top: 0;
	background: $light;
	padding-top: 0;
}

.page.our-shire {
	.entry-content {
		&.py-6.pb-8 pb-sm-10 pb-lg-12 {
			border-bottom: 6.5rem solid $light;
			padding-bottom: 0 !important;
		}
	}
}
