.page-header {
  background-color: $lighter;
}

.page-header__row {
  position: relative;
}

.page-header__img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1400 / 450;
  object-position: center;
  max-height: 40.875rem;
}

.home {
  .page-header__img {
    aspect-ratio: 1400 / 654;
  }
}
