.content-block {
  table {
    margin-bottom: 2.5rem;
    tr {
      border-bottom: 1px solid #B4D8E6;
      line-height: 1.325;
    }
    td,
    th {
      @include font-size(14);
      line-height: 1.325;
      letter-spacing: 0.02em;
      color: $gray-900;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      strong, b {
        font-weight: 600;
      }
    }
  }
}
