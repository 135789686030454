@use "sass:math";

.card-img-top {
  object-fit: cover;
  height: auto;
  width: 100%;
}

.card-header {
  display: flex;
  align-items: center;
  border: 0;
  svg {
    width: 6rem;
    @include bp($lg) {
      width: 7rem;
    }
    height: auto;
    max-width: 50%;
    display: block;
    margin: auto;
    object-fit: contain;
  }
}

.card-footer {
  border: 0;
}

.card-chip {
  position: absolute;
  transform: translate3d(0, -50%, 0);
  font-weight: 700;
  @include font-size(16);
  letter-spacing: 0.05em;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 2px;
  z-index: 2;
  background: $accent;
}

.card {
  &.card-event {
    .card-header {
      background: #f2c0cc;
      aspect-ratio: 370 / 218;
      svg {
        switch {
          fill: #f8dfe5 !important;
        }
      }
    }
  }
  &.card-news {
    .card-header {
      background: #B4DCEB;
      aspect-ratio: 370 / 218;
      svg {
        switch {
          fill: #d9edf5 !important;
        }
      }
    }
  }
  &.card-notice {
    .card-header {
      aspect-ratio: 370 / 218;
    }
  }
  &.card-location-xl {
    .card-header {
      img {
        aspect-ratio: 16 / 9;
        @include bp($lg) {
          aspect-ratio: 788 / 550;
        }
        height: 100%;
      }
      &.cover-image {
        height: 100%;
      }
    }
  }
  &.card-staff {
    .card-header {
      background-color: #CEE9E9;
      img {
        aspect-ratio: 16 / 9;
        height: 100%;
      }
      &.cover-image {
        height: 100%;
      }
    }
    .card-img-top {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      >svg path,
      >svg switch {
        fill: rgba(#fff, 0.5) !important;
      }
      &::after {
        display: none;
      }
    }
  }
  &.card-notice,
  &.card-location,
  &.card-locations {
    .card-header {
      background: #CEE9E9;
      svg {
        switch {
          fill: #e6f4f4 !important;
        }
      }
    }
  }
  &.card-location {
    .card-header {
      svg {
        width: 5rem;
      }
    }
    .card-img-top {
      aspect-ratio: 786 / 550;
      height: 100%;
      object-fit: cover;
    }
  }
  &.card-staff,
  &.card-locations {
    border: 1px solid #D9DBE0;
    border-radius: 1px;
    border-bottom: 3px solid $primary;
  }
  &.card-locations {
    .card-header {
      svg {
        width: 3rem;
      }
    }
  }
}

.card-info {
  white-space: normal;
  display: flex;
  width: 100%;
}

.card-info__th {
  font-weight: 700;
  color: $primary;
  display: inline-block;
  margin-right: 1rem;
  line-height: math.div(22, 16);
}

.card-info__td {
  font-weight: 400;
  color: $gray-900;
  display: inline-block;
  letter-spacing: 0.02em;
  line-height: math.div(22, 16);
}


.card-info__td--retiring {
  color: $gray;
}

.card-icons {
  svg {
    width: 1.125rem;
    height: 1.125rem;
    display: inline-flex;
    align-items: baseline;
    justify-content: center;
    margin-right: 0.75rem;
    path,
    switch {
      fill: $primary-dark;
    }
  }
}

.card-th {
  @include font-size(14);
  line-height: math.div(18, 14);
  font-weight: 700;
  letter-spacing: 0.03em;
  display: inline-block;
  margin-right: 1.3125rem;
  color: $primary;
}

.card-locations__link {
  display: inline-flex;
  width: auto;
  border-bottom: 2px solid;
  svg {
    width: 10px;
    margin-left: 6px;
  }
}

.card-staff,
.card-locations {
  height: 100%;
}

.card:not(.card-location-xl) {
  .card-header {
    .card-img-top {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    >.card-img-top,
    >svg {
      aspect-ratio: 16 / 9;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.chip {
  font-weight: 700;
  @include font-size(16);
  letter-spacing: 0.05em;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 2px;
  z-index: 2;
}

.card-locations {
  .card-body-wrapper {
    >.row {
      flex-flow: row;
    }
    >.row__email {
      word-break: break-all;
    }
  }
}


.single-img-top {
  &.single-img-top__placholder {
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    .single-img-top__placholder--img {
      aspect-ratio: 1 / 1;
      width: 20%;
      object-fit: contain;
    }
  }
}
