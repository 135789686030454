/*------------------------------------*\
  # SPLIT
	This is a side by side layout.
	The proportions of the sides can be different.
\*------------------------------------*/

[class*="l-split"] {
	--space: 40px;
	--space-tablet: 80px;

	display: grid;
	gap: var(--space, 40px);
	align-items: start;

	@include bp($tablet) {
		gap: var(--space-tablet, 80px);
	}

}

.l-split-1\:1 {

	@include bp($tablet) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	}

}


.l-split-2\:3 {
	--space: 20px;
	--space-tablet: 40px;

	@include bp($tablet) {
		grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
	}

}

/* = NO GAP
----------------------------------------------- */

.l-split--no-gap {
	gap: 0;
}