/*------------------------------------*\
  # UTILITIES MASTER
\*------------------------------------*/

@import "colors";
@import "image-cover";
@import "bg-loading";
@import "elevation";

.list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-reset li {
  margin: 0;
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.border-none {
  border-style: none;
}

// enable smooth scroll to anchor behaviour
html {
  scroll-behavior: smooth;
}
