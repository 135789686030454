/*------------------------------------*\
	# NO IMAGE - IMAGE REPLACEMENT
\*------------------------------------*/

@use "sass:math";

.c-no-image {
	width: 100%;
	position: relative;
	background-color: color__(primary);
}

.c-no-image::before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
		top: 0;
		left: 0;
	background: color__(primary);
	opacity: 0.8;
}

// This keeps the basic image ratio but it can be TALLER
.c-no-image__replacement {
	width: 100%;
	height: 0;
	padding-bottom: percentage(math.div(214, 380));
}

.c-no-image__replacement-icon {
	position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	display: flex;
		align-items: center;
		justify-content: center;

	width: 100%;
	height: 100%;
	padding: 40px;

	opacity: 0.5;

	> svg {
		max-width: 120px;
	}

}
