/*------------------------------------*\
	# LAYOUT - CLUSTER
	@link https://every-layout.dev/layouts/cluster/
\*------------------------------------*/

.l-cluster {
	--space: 20px;
	--space-desktop: 30px;

	display: flex;
	flex-wrap: wrap;
	gap: var(--space, 1rem);
	justify-content: flex-start;
	align-items: center;

	@include bp($desktop) {
		gap: var(--space-desktop, 1.5rem);
	}

}