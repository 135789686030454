.swiper {
	&.swiper__location-card {
		margin: -0.5rem;
	}
}

/*------------------------------------*\
	# SWIPERS
\*------------------------------------*/

.swiper__location-card {
	--swiper-navigation-sides-offset: -20px;
	--swiper-theme-color: #{#e1e1e1};
	--swiper-pagination-bullet-inactive-color: #{#dfdfdf};
	--swiper-pagination-bullet-inactive-opacity: 0.9;
	--swiper-pagination-bullet-horizontal-gap: 8px;
	--swiper-pagination-bullet-size: 14px;

	@include bp($tablet) {
		--swiper-navigation-sides-offset: -22px;
	}

	// @include bp($desktop-m) {
	// 	--swiper-navigation-sides-offset: -64px;
	// }

	position: relative;
}

.swiper-slide {
	height: auto;

	display: flex;
}

.swiper-button-prev,
.swiper-button-next {
	--swiper-navigation-size: 52px;
	transition: opacity 0.125s linear;
}

.swiper-pagination-bullets {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
.swiper-pagination-bullet {
	transition: background-color 0.125s linear;
	&:hover {
		background-color: #b1b1b1;
	}
	&.swiper-pagination-bullet-active {
		background-color: $primary;
	}
}

/* = IMAGE SLIDE
----------------------------------------------- */

.swiper-slide-image {
	display: flex;
	flex-direction: column;
}

.swiper-slide-image .image-cover {
	flex: 1 0 auto;
}

/* = THEMES
----------------------------------------------- */

.swiper__container--dark {
	--swiper-navigation-color: #{$primary-lighter};
}

.swiper__location-card {
	.swiper-button-prev {
		display: none;
		@include bp($lg) {
			display: flex;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		@include bp($sm) {
			display: flex;
		}
		width: 52px;
		height: 52px;
		background: $secondary;
		border-radius: 50%;
		align-items: center;

		&:after {
			font-size: 14px;
			color: #fff;
		}

	}
	.swiper-pagination-bullets {
		bottom: -2rem;
	}
}
