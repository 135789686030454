@use "sass:math";

.infobox {
  padding: 3rem;
}

.info-box-h4 {
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: math.div(31.2, 24);
  @include font-size(24);
  text-align: left;
  margin-bottom: 3rem;
}

.btn {
  &.btn-link-callout {
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }
}

.infobox__link--wrapper {
  >p:last-of-type {
    margin-bottom: 0;
  }
}

.info-box-primary {
  background: tint($primary, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $primary;
    }
  }
}

.info-box-secondary {
  background: tint($secondary, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $secondary;
    }
  }
}

.info-box-success {
  background: tint($success, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $success;
    }
  }
}

.info-box-info {
  background: tint($info, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $info;
    }
  }
}

.info-box-warning {
  background: tint($warning, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $warning;
    }
  }
}

.info-box-danger {
  background: tint($danger, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $danger;
    }
  }
}

.info-box-light {
  background: tint($light, 80%);
  color: #9a968b;
  .btn.btn-link-chevron {
    color: #9a968b;
    &::after {
      background: $light;
    }
  }
}

.info-box-dark {
  background: tint($dark, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $dark;
    }
  }
}

.info-box-accent {
  background: tint($accent, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $accent;
    }
  }
}

.info-box-alert {
  background: tint($alert, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $alert;
    }
  }
}

.info-box-green {
  background: tint($green, 80%);
  .btn.btn-link-chevron {
    &::after {
      background: $green;
    }
  }
}

.info-box-yellow {
  background: tint($yellow, 80%);
  color: #9d920c;
  .btn.btn-link-chevron {
    color: #9d920c;
    &::after {
      background: $yellow;
    }
  }
}
