.br-50 {
  border-radius: 50% !important;
}

.br-0 {
  border-radius: 0 !important;
}

.br-999 {
  border-radius: 999em !important;
}

.br-17 {
  border-radius: 17px !important;
  @include bp($lg) {
    border-radius: 21px !important;
  }
}

.bg-none {
  background: none !important;
}

.b-0 {
  border-width: 0 !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.bs-0 {
  box-shadow: none !important;
}

.ls-0 {
  letter-spacing: 0 !important;
}

.ls-1 {
  letter-spacing: 0.01em !important;
}

.ls-2 {
  letter-spacing: 0.02em !important;
}

.ls-3 {
  letter-spacing: 0.03em !important;
}

.ls-4 {
  letter-spacing: 0.04em !important;
}

.ls-5 {
  letter-spacing: 0.05em !important;
}

.ls-6 {
  letter-spacing: 0.06em !important;
}

.ls-7 {
  letter-spacing: 0.07em !important;
}

.ls-8 {
  letter-spacing: 0.08em !important;
}

.ls-9 {
  letter-spacing: 0.09em !important;
}

.ls-10 {
  letter-spacing: 0.1em !important;
}

.fw-100 {
  font-weight: 100 !important;
  &::placeholder {
    font-weight: 100 !important;
  }
}

.fw-200 {
  font-weight: 200 !important;
  &::placeholder {
    font-weight: 200 !important;
  }
}

.fw-300 {
  font-weight: 300 !important;
  &::placeholder {
    font-weight: 300 !important;
  }
}

.fw-400 {
  font-weight: 400 !important;
  &::placeholder {
    font-weight: 400 !important;
  }
}

.fw-500 {
  font-weight: 500 !important;
  &::placeholder {
    font-weight: 500 !important;
  }
}

.fw-600 {
  font-weight: 600 !important;
  &::placeholder {
    font-weight: 600 !important;
  }
}

.fw-700 {
  font-weight: 700 !important;
  &::placeholder {
    font-weight: 700 !important;
  }
}

.font-italic {
  font-style: italic !important;
}


.col-md-20pc {
  @include bp($md) {
    max-width: 20% !important;
    width: 20% !important;
    flex: 0 0 20% !important;
  }
}

.col-lg-20pc {
  @include bp($lg) {
    max-width: 20% !important;
    width: 20% !important;
    flex: 0 0 20% !important;
  }
}

.container {
  &.container-sm {
    @include bp($xl) {
      max-width: 1140px;
    }
    @include bp($xxl) {
      max-width: 1200px;
    }
  }
}

.fs-8 {
  @include font-size(8);
}

.fs-9 {
  @include font-size(9);
}

.fs-10 {
  @include font-size(10);
}

.fs-11 {
  @include font-size(11);
}

.fs-12 {
  @include font-size(12);
}

.fs-13 {
  @include font-size(13);
}

.fs-14 {
  @include font-size(14);
}

.fs-15 {
  @include font-size(15);
}

.fs-16 {
  @include font-size(16);
}

.fs-17 {
  @include font-size(17);
}

.fs-18 {
  @include font-size(18);
}

.fs-19 {
  @include font-size(19);
}

.fs-20 {
  @include font-size(20);
}

.fs-21 {
  @include font-size(21);
}

.fs-22 {
  @include font-size(22);
}

.fs-23 {
  @include font-size(23);
}

.fs-24 {
  @include font-size(24);
}

.fs-25 {
  @include font-size(25);
}

.fs-26 {
  @include font-size(26);
}

.fs-27 {
  @include font-size(27);
}

.fs-28 {
  @include font-size(28);
}

.fs-29 {
  @include font-size(29);
}

.fs-30 {
  @include font-size(30);
}

.fs-31 {
  @include font-size(31);
}

.fs-32 {
  @include font-size(32);
}

.fs-33 {
  @include font-size(33);
}

.fs-34 {
  @include font-size(34);
}

.fs-35 {
  @include font-size(35);
}

.fs-36 {
  @include font-size(36);
}

.fs-37 {
  @include font-size(37);
}

.fs-38 {
  @include font-size(38);
}

.fs-39 {
  @include font-size(39);
}

.fs-40 {
  @include font-size(40);
}

.fs-41 {
  @include font-size(41);
}

.fs-42 {
  @include font-size(42);
}

.fs-43 {
  @include font-size(43);
}

.fs-44 {
  @include font-size(44);
}

.fs-45 {
  @include font-size(45);
}

.fs-46 {
  @include font-size(46);
}

.fs-47 {
  @include font-size(47);
}

.fs-48 {
  @include font-size(48);
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}
