/*------------------------------------*\
	# BASE ELEMENTS
\*------------------------------------*/

/* For more advanced media work head over to the media section in modules. 
This is just for the single elements. */

html {
	box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: color__(background-body); /* Fallback for when there is no custom background color defined. */
}

hr {
	background-color: color__(background-hr);
	border: 0;
	height: 1px;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 0; /* Extra wide images within figure tags don't overflow the content area. */
}

// INCLUDES
@import "tables";
