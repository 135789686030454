@use "sass:math";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  b {
    font-weight: 800;
    letter-spacing: 0.05em;
    color: tint($primary, 5%);
  }
}

.h1 {
  @include font-size(50);
  line-height: (55*0.02);
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}

.h2 {
  @include font-size(35);
  line-height: math.div(48, 35);
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}

.h3 {
  @include font-size(30);
  line-height: math.div(36, 30);
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}

.h4 {
  @include font-size(25);
  line-height: 1.5;
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}

.h5 {
  @include font-size(20);
  line-height: 1.5;
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}

.h6 {
  @include font-size(16);
  line-height: 1.5;
  letter-spacing: 0.03em;
  font-weight: 700;
  color: $primary;
}
