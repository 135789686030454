@use "sass:math";

.gfield--type-honeypot {
  position: absolute;
  left: -99999em;
}

.gform_fields {
  list-style: none;
  padding-left: 0;
  .gfield {
    .form-control {
      border-color: $primary;
      &:focus {
        box-shadow: 0 0 0 2px rgba($primary, .25);
      }
    }
    .form-label {
      @include font-size(18);
      font-weight: 500;
      line-height: math.div(20, 18);
      letter-spacing: 0.02em;
      color: $primary;
      .gfield_required {
        color: $primary;
        margin-left: 2px;
      }
    }
    .gfield-choice-input.form-check-input {
      border-color: $primary;
      border-radius: 0;
    }
    .gform-field-label.form-check-label {
      color: $primary;
      cursor: pointer;
    }
  }
  .form-control {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    min-height: 3.75rem;
    &.textarea {
      min-height: 8.375rem;
    }
  }
  .gfield_checkbox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
    margin: 1.25rem 0 0;
  }
}

.form-check {
  padding-right: 2rem;
  margin-bottom: 1rem;
}

// .gform_footer {
//   display: flex;
//   justify-content: flex-end;
// }

.form-block {
  .gform_button.btn.btn-form-submit {
    @include font-size(18);
    font-weight: 500;
    line-height: math.div(20, 18);
    letter-spacing: 0.02em;
    padding: 1rem 1.625rem;
    margin-left: auto;
    display: block;
    transition: all 0.2s ease-in-out;
  }
}

.form-block {
  &:not(.form-block--dark) {
    .gform_button.btn.btn-form-submit {
      color: $primary;
      &:active,
      &:hover,
      &:focus {
        background: $primary;
        color: #fff;
      }
      &:focus {
        box-shadow: 0 0 0 2px rgba($primary, .25);
      }
    }
  }
}

.border-dashed-01 {
  background-image: $svg-dashed-box-01-primary;
}

.border-dashed-01-gray {
  background-image: $svg-dashed-box-01-gray;
}

.gform_drop_area {
  .btn.btn-sm.btn-link {
    &:hover {
      background: none;
    }
  }
}

.ginput_preview {
  line-height: 1.5;
}
