	/*------------------------------------*\
	# TABLE OF CONTENTS
\*------------------------------------*/
@import "modules/fonts";

@import "variables-settings/global-variables";
@import "variables-settings/css-variables";

/* = BASELINES
----------------------------------------------- */

// Useful mixins like clearfix.
@import "mixins/mixins-master";

// Let's get the basics down for elements and components.
@import "base/elements/elements-master";

// All of the typography is handled here.
// @import "base/typography/typography-master";

// Accesibility.
@import "base/accessibility";

// Gravity Forms Bootstrap 5
@import "gravity/gravityforms";
@import "gravity/animate-labels";

// Layout Stack
@import "layout/stack";

// Layout Tools
@import "layout/layout-tools";

@import "datatables/common.scss";
@import "datatables/dataTables.bootstrap5.scss";

// A composition of components from the base.
@import "site/site-master";

// Module for navigation, main-navigation, menus, sidebar menus
@import "navigation/navigation-master";

// Buttons
@import "modules/buttons";

// Icons
@import "site/icons";

// No Image
@import "site/no-image";
@import "site/input-groups";

// Alignments for WYSIWYG aligning
@import "layout/alignments";

// Modules for managing collective images. This is also often used in the WYSIWYG
@import "media/media-master";

// Base form management.
// @import "base/forms/forms-master";
@import "base/buttons";

// Layouts, it's final
@import "layout/layout-master";

// Utilites Master
@import "utilities/utilities-master";

// Navigation
@import "navigation/nav-collapse";
@import "navigation/nav-hamburger";
@import "navigation/nav-topbar";
@import "navigation/navigation";

// Footer
@import "footer/footer";
@import "footer/footer-upper";
@import "footer/footer-middle";
@import "footer/footer-lower";
@import "footer/footer-info-boxes";
@import "footer/footer-current-events";

// Pages
@import "page/page-header";
@import "page/breadcrumbs";

// Modules
@import "modules/swiper";
@import "modules/max-widths";
@import "modules/single";
@import "modules/section-separators";
@import "modules/accordion";
@import "modules/cards-full";
@import "modules/headings";
@import "modules/flexi-two-col";
@import "modules/alerts";
@import "modules/callout";
@import "modules/form-block";
@import "modules/form-block-dark";
@import "modules/sidebar-content";
@import "modules/file-download-table";
@import "modules/info-box";
@import "modules/tables";
@import "modules/alert-topbar";
@import "modules/icon-box-links";
@import "modules/content-block";
@import "modules/page-emergency";
@import "modules/instagram";
@import "modules/relationships";
@import "modules/fw-two-col-images";
@import "modules/iframes";
@import "modules/front-page";
@import "modules/search";

// Shame Shame Shame
@import "helpers/helpers";
@import "helpers/animations";
@import "shame";
@import "stupid-shit";
