@use "sass:math";

.callout {
  padding: 3rem;
}

.callout-h4 {
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: math.div(31.2, 24);
  @include font-size(24);
  text-align: left;
}

.btn {
  &.btn-link-callout {
    transition: all 0.2s $transition-main;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }
}

.callout-primary {
  background: tint($primary, 80%);
}

.callout-secondary {
  background: tint($secondary, 80%);
}

.callout-success {
  background: tint($success, 80%);
}

.callout-info {
  background: tint($info, 80%);
}

.callout-warning {
  background: tint($warning, 80%);
}

.callout-danger {
  background: tint($danger, 80%);
}

.callout-light {
  background: tint($light, 80%);
  color: #9a968b;
  .btn-outline-light {
    color: #9a968b;
  }
}

.callout-dark {
  background: tint($dark, 80%);
}

.callout-accent {
  background: tint($accent, 80%);
}

.callout-alert {
  background: tint($alert, 80%);
}

.callout-green {
  background: tint($green, 80%);
}

.callout-yellow {
  background: tint($yellow, 80%);
  color: #9d920c;
  .btn-outline-yellow {
    color: #9d920c;
  }
}
