/* = ANIMATE LABELS
----------------------------------------------- */

$input-padding-horizontal: 10px;
$input-padding-vertical: 10px;
$field_space: 10px;
$color__text-input-focus: #ccc;

.form-block {

  .animate-label {
    position: relative;
  }

  .animate-label > label {
  	position: absolute;
  	display: inline-block;
  	transition: transform .2s ease-in-out;
  	transform: translate3d(6px, 12px, 0);
    padding: 0 8px 0 10px;
    background: $body-bg;
    line-height: 1.75rem;
    // min-height: 3.75rem;
    min-height: 0.75rem;
  }

  .animate-label .gfield_label--shift {
    background: $body-bg;
    padding: 0 8px 0 10px;
    transform: translate3d(6px, -12px, 0);
  }

  .gform_wrapper.gravity-theme .gfield_label {
    display: inline-block;
  	@include font-size(16);
    font-weight: 400;
    margin-bottom: 8px;
    padding: 0;
    color: $primary-dark;
    font-size: 16px;
  }

  .gform_wrapper.gravity-theme .gfield input,
  .gform_wrapper.gravity-theme .gfield select,
  .gform_wrapper.gravity-theme .gfield textarea {
    width: 100%;
    padding: 15px 10px;
    color: $primary-darkest;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid;
  }

  .gfield_captcha label {
    padding: 0 15px;
  }

  .gform_wrapper.gravity-theme .gform_footer input {
    color: $secondary;
    padding: 15px 35px !important;
  }

  .border-bottom {
    border-bottom: 1px solid hsl(0deg 0% 62% / 32%);
  }

  &.form-block--dark {
    .gform_wrapper.gravity-theme .gfield input,
    .gform_wrapper.gravity-theme .gfield select,
    .gform_wrapper.gravity-theme .gfield textarea {
      color: $primary-light;
    }
    .gform_wrapper.gravity-theme .gfield_label {
      color: $primary-lightest;
    }
  }

}
