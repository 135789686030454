@use "sass:map";

/*------------------------------------*\
  # COLOURS
\*------------------------------------*/
// @debug $colors_map_master: map.merge($color-vars-palette, $color-vars-applied, $theme-colors, $color-vars-theme);
// @debug $color_map_bg: map.merge($color-vars-palette, $color-vars-applied);

/* = TEXT
----------------------------------------------- */
// @each $color_name, $color_value in $colors_map_master {
//   .color-#{""+$color_name},
//   .text-#{""+$color_name} {
//     color: $color_value !important;
//     &::placeholder {
//       color: rgba($color_value, 0.875) !important;
//     }
//     svg {
//       * {
//         fill: $color_value !important;
//       }
//     }
//   }
// }

@each $color_name, $color_value in $color-vars-palette {
  .color-#{""+$color_name},
  .text-#{""+$color_name} {
    color: $color_value !important;
    &::placeholder {
      color: rgba($color_value, 0.875) !important;
    }
    svg {
      * {
        fill: $color_value !important;
      }
    }
  }
  .bg-#{""+$color_name} {
    background: $color_value !important;
  }
}

// @each $color_name, $color_value in $color-vars-applied {
//   .color-#{""+$color_name},
//   .text-#{""+$color_name} {
//     color: $color_value;
//     &::placeholder {
//       color: rgba($color_value, 0.875) !important;
//     }
//     svg {
//       * {
//         fill: $color_value !important;
//       }
//     }
//   }
// }


// @each $color_name, $color_value in $theme-colors {
//   .color-#{""+$color_name},
//   .text-#{""+$color_name} {
//     color: $color_value;
//     &::placeholder {
//       color: rgba($color_value, 0.875) !important;
//     }
//     svg {
//       * {
//         fill: $color_value !important;
//       }
//     }
//   }
// }

// @each $color_name, $color_value in $color-vars-theme {
//   .color-#{""+$color_name},
//   .text-#{""+$color_name} {
//     color: $color_value;
//     &::placeholder {
//       color: rgba($color_value, 0.875) !important;
//     }
//     svg {
//       * {
//         fill: $color_value !important;
//       }
//     }
//   }
// }

/* = BACKGROUND
----------------------------------------------- */
// @each $color_name, $color_value in $color_map_bg {
//   .bg-#{""+$color_name} {
//     background: $color_value !important;
//   }
// }

// @each $color_name, $color_value in $color-vars-palette {
//   .bg-#{""+$color_name} {
//     background: $color_value !important;
//   }
// }

// @each $color_name, $color_value in $color-vars-applied {
//   .bg-#{""+$color_name} {
//     background-color: $color_value;
//   }
// }

/* = BORDER
----------------------------------------------- */
.footer-info-boxes {
  .color-secondary-lighter svg *,
  .text-secondary-lighter svg * {
    fill: hsl(347 68% 75% / 1) !important;
  }
}
