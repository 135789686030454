.icon-transform {
  width: 44px !important;
  height: auto !important;
  @include bp($nv) {
    width: 62px !important;
  }
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  cursor: pointer;
  stroke: #FAF3E6;
}
