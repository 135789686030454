/*------------------------------------*\
	# MIXINS MASTER
\*------------------------------------*/

// Rem output with px fallback
@use "sass:math";
@import "../../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss";

@mixin font-size($sizeValue: 16) {
	font-size: $sizeValue + px;
	font-size: math.div($sizeValue, 16) + rem;
}

// Rem output with px fallback. Can be passed any property such as line-height or margin-top.
@mixin rem($property, $sizeValue: 16) {
	#{$property}: $sizeValue + px;
	#{$property}: math.div($sizeValue, 16) + rem;
}

// Center block
@mixin center-block {
	display: block;
	margin: 0 auto;
}

/**
 * Create Alias for breakpoint()
 * @link https://stackoverflow.com/questions/50038602/how-to-create-sass-mixin-aliases-with-support-for-content-blocks
 */
@mixin bp($args...) {
	@include breakpoint($args...) {
		@content;
	}
}
