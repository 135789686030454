@use "sass:math";

.input-group {
  &.input-group__search {
    width: 100%;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    font-size: $btn-font-size-lg;
    line-height: $btn-line-height-lg;
    @include bp($lg) {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      font-size: $btn-font-size-lg;
      line-height: $btn-line-height-lg;
    }
  }
  .form-control {
    letter-spacing: 0.05em;
    line-height: math.div(24, 16);
    font-weight: 600;
  }
}

