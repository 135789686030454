@use "sass:math";

.footer__lower-wrapper {
  @include font-size(14);
  line-height: math.div(20, 14);
  letter-spacing: 0.02em;
  font-weight: 400;
  padding: 0.5rem 0 0;
  color: $primary-light;
  .sep-vertical {
    display: inline-block;
    width: 1px;
    height: 1rem;
    background: rgba($primary-light, 0.75);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.link-credit {
  display: inline-block;
  color: $primary-light;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
  margin-left: 0.25rem;
}

.btn {
  &.btn-back-to-top {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $primary-light;
    display: inline-flex;
    align-items: center;
    &::after {
      opacity: 0;
      left: 1rem;
      content: " ";
      display: block;
      position: absolute;
      height: 2px;
      background-image: $svg-hsep-01-primary;
      width: calc(100% - 2rem);
      bottom: 0;
      transition: all 0.125s linear;

    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
    svg {
      margin-left: 0.875rem;
    }
  }
}

.nav {
  &.footer-links {
    .nav-item {
      position: relative;
      .nav-link {
        color: $primary-light;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
      &::before {
        content: " ";
        position: absolute;
        width: 1px;
        height: 1rem;
        background: rgba($primary-light, 0.75);
        top: calc(50% - 0.5rem);
      }
      &:first-of-type {
        &::before {
          display: none;
        }
      }
    }
  }
}

@include bp(0 $nv) {
.footer__lower-wrapper>.container>.row>.col-48 {    display: flex;
    justify-content: center;
    align-items: center;
  }
  #footer-links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footer-links__container {
  justify-content: center;
  display: flex;
  padding: .5rem 0;
  @include bp($lg) {
    justify-content: start;
    padding: 0;
  }
}

.menu-accessibility-notice {
  &::before {
    display: none;
  }
  @include bp(362px) {
    &::before {
      display: block;
    }
  }
}
