[data-svg-swoosh-id="12d6999c"][data-block-id="a6d45600"],
[data-svg-swoosh-id="3bd622c0"][data-block-id="ff37da0d"] {
  margin-top: 0;
}

.fw-two-col-images[data-block-id="82a8343c"],
.fw-two-col-images[data-block-id="8eaa4adf"],
.fw-two-col-images[data-block-id="87f87322"],
.fw-two-col-images[data-block-id="ec93b635"],
.fw-two-col-images[data-block-id="1c26cfcc"],
.fw-two-col-images[data-block-id="721d4dc5"],
.fw-two-col-images[data-block-id="197688d2"]
{
  margin-bottom: calc((6.5rem + 182px) * -1);
}
