@use "sass:math";

.navbar-collapse {
  .container {
    max-width: 100%;
    @include bp($xxl) {
      max-width: 1200px;
    }
  }
}


.navbar-upper,
.navbar-lower {
  >.nav-item.collapse-show {
    >.nav-link {
      &::before {
        background-color: rgba(#fff, 0.925) !important;
      }
    }
  }
  >.nav-item {
    @include bp(0 $nv) {
      margin: 0 !important;
      padding: 0;
    }
    >.nav-link {
    position: relative;
    &:hover {
      &::before {
        background-color: rgba(#fff, 0.425);
      }
    }
    &.active {
      &::before {
        background-color: rgba(#fff, 0.625);
      }
    }
    &.active:hover {
      &::before {
        background-color: rgba(#fff, 0.75);
      }
    }
    @include bp(0 $nv) {
      padding: 0.9375rem 0;
    }
    @include bp(0 $nv) {
      margin: 0 !important;
      @include bp(0 $nv) {
        line-height: 1;
        margin: 0;
        &::before {
          content: " ";
          width: 100%;
          height: 1px;
          position: absolute;
          background-color: $submenu-border;
          // top: 56px;
          bottom: 0;
          transition: background-color 0.125s linear;
        }
      }
    }
  }
    >.sub-menu {
      padding-left: 0;
      display: none;
      @include bp($nv) {
        padding-left: 0.875rem;
        display: block;
      }
      @include bp($lg) {
        padding-left: 1.2rem;
      }
      >.nav-item {
        line-height: 1.1111;
        padding-left: 0;
        >.nav-link {
          padding: 0.9375rem 0;
          @include bp($nv) {
            padding: 0.375rem 1rem;
          }
          @include bp($lg) {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}

#navbarToggler {
  background-color: $primary;
  will-change: height;
  @include bp($xl) {
    background-color: $primary;
    background-image: $svg-swoosh-01-light-xl;
    // background-size: auto calc(100% - 2rem);
    background-size: 100% 14rem;
    background-position: bottom center;
    background-repeat: no-repeat;
  }
}

.navbar-toggler {
  transition: all 0.125s $transition-main;
  &:hover,
  &:active,
  &:focus,
  &[aria-expanded="true"] {
    background: #dd4b6d !important;
  }
}

.navbar-upper {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
  padding: 1.375rem 1.875rem 0;
  position: relative;
  &::before {
    content: " ";
    background-image: $svg-bullet-01;
    background-repeat: repeat-x;
    width: calc(100% - 8.1rem);
    position: absolute;
    height: 2px;
    top: 5.625rem;
    display: none;
  }
  @include bp($nv) {
    padding: 2.375rem 1.875rem 0;
    &::before {
      display: block;
    }
  }
  @include bp($md) {
    padding: 2.375rem 2rem 0;
  }
  @include bp($lg) {
    padding: 2.375rem 2rem 0;
  }
  @include bp($xl) {
    padding: 2.375rem 4rem 0;
    &::before {
      top: 6.325rem;
    }
  }
  >.nav-item {
    margin-bottom: 1.5rem;
    position: relative;
    &:nth-last-child(1) {
      margin-bottom: 0;
      @include bp($nv) {
        margin-bottom: 1.5rem;
      }
    }
    @include bp(900px) {
      margin-bottom: 2rem;
    }
    @include bp($nv 900px) {
      &:nth-child(3) {
        position: relative;
        &::before {
          background-image: $svg-bullet-01;
          background-repeat: repeat-x;
          content: " ";
          height: 2px;
          position: absolute;
          top: 3.125rem;
          width: calc(200% - 4rem);
        }
      }
    }
    >.sub-menu {
      margin-top: 0;
      padding-top: 0;
      @include bp($nv) {
        border-top: none;
        margin-top: 1.75rem;
      }
      >.nav-item {
        list-style: none;
        position: relative;
        @include font-size(18);
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: math.div(20, 18);
        transition: color 0.2s ease-in-out;
        &:hover,
        &:active,
        &:focus {
          color: $white;
          &::before {
            transform: translate3d(0.25rem, 0, 0);
          }
          @include bp($nv) {
            &::before {
              transform: translate3d(0.5rem, 0, 0);
            }
          }

        }
        &::before {
          position: absolute;
          width: 8px;
          height: 12px;
          top: calc(50% - 6px);
          transition: transform 0.2s ease-in-out;
          will-change: transform;
          display: block;
          background-image: $svg-chevron-right-01-white;
          background-repeat: no-repeat;
          content: " ";
          left: calc(100% - 0.625rem);
          @include bp($nv) {
            left: -0.625rem;
            width: 6px;
            height: 10px;
            background-image: $svg-chevron-right-01;
          }
          @include bp($lg) {
            left: -1rem;
          }
        }
        &::after {
          display: block;
          content: " ";
          background-image: $svg-bullet-01;
          background-repeat: repeat-x;
          width: 100%;
          position: absolute;
          height: 2px;
          bottom: 0;
        }
        @include bp($nv) {
          &::before {
            display: block;
          }
          &::after {
            display: none;
          }

        }
        @include bp($lg) {
          line-height: (20*0.05);
        }
      }
    }
  }
}

.navbar-lower {
  display: flex;
  flex-flow: column;
  @include bp($nv) {
    flex-flow: row;
  }
  .nav-item {
    margin-right: 4rem;
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid $submenu-border;
    @include bp($nv) {
      border-bottom: none;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:hover,
    &:active,
    &:focus,
    .active {
      >.nav-link {
        color: $white;
      }
    }
    >.nav-link {
      padding-right: 0;
      padding-left: 0;
      position: relative;
      transition: color 0.2s ease-in-out;
    }
  }
}

.nav-link__dotted-border {
  position: relative;
  &::after {
    content: " ";
    background-image: $svg-bullet-01;
    background-repeat: repeat-x;
    width: 100%;
    position: absolute;
    height: 2px;
    bottom: -0.875rem;
    display: none;
    transition: all 0.125s $transition-main;
    @include bp($md) {
      display: block;
    }
  }
  &:hover,
  &:active,
  &:focus,
  &.active {
    &::after {
      background-image: $svg-bullet-01-active;
    }
  }
}

.navbar-lower__container {
  padding: 0 1.875rem 2.3125rem;
  @include bp($nv) {
    padding: 2rem 1.875rem 4rem;
  }
  @include bp($md) {
    padding: 4rem 2rem 4rem;
  }
  @include bp($lg) {
    padding: 6rem 2rem 5rem;
  }
  @include bp($xl) {
    padding: 6rem 4rem 5rem;
  }
  background-image: $svg-swoosh-01-light-xl;
  background-size: auto calc(100% - 0.5rem);
  @include bp($md) {
    background-size: auto calc(100% - 1rem);
  }
  @include bp(900px) {
    background-size: auto calc(100% - 2rem);
  }
  background-position: bottom center;
  background-repeat: no-repeat;
  @include bp($xl) {
    background: none;
  }
}

.nav-link-upper,
.navbar-lower .nav-link {
    color: $light;
    @include font-size(20);
    line-height: (26*0.05);
    font-weight: 600;
    letter-spacing: 0.03em;
    @include bp($lg) {
      @include font-size(22);
      line-height: math.div(25, 22);
    }
    @include bp($xl) {
      @include font-size(30);
      line-height: math.div(39, 30);
    }
}

.nav-navbar__left {
  .navbar-brand-svg {
    padding: 0;
    width: 128px;
    height: auto;
    shape-rendering: geometricPrecision;
    @include bp($nv) {
      width: auto;
    }
  }
  .navbar-brand {
    padding: 13px 16px 12px;
    @include bp($nv) {
      width: inherit;
      padding: 18px 25px;
    }
  }
}

.nav-navbar__right {
  .navbar-toggler {
    padding: 8px;
    padding-left: 24px;
    @include bp($nv) {
      padding: 20px;
      padding-left: 50px;
    }
  }
}

.navbar-toggler {
  display: flex;
  align-items: center;
  color: var(--color-white);
}

.navbar-toggler__text {
  @include font-size(15);
  text-transform: uppercase;
  letter-spacing: 0.100em;
  font-weight: 600;

  @include bp($tablet) {
    @include font-size(17);
  }

}

header {
  [class*=navbar] {
    .nav-item {
      .nav-link {
        transition: all 0.125s $transition-main;
      }
      &:hover,
      &:active,
      &:focus,
      &.active {
        .nav-link {
          color: #fff;
        }
      }
    }
  }
}
