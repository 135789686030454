/*------------------------------------*\
  # BOX
\*------------------------------------*/

.box-1 {
	padding: var(--spacer-16, 20px);

	@include bp($tablet-p) {
		padding: var(--spacer-32);
	}

	@include bp($desktop) {
		padding: var(--spacer-64);
	}

}

.box-2 {
	padding: var(--spacer-32, 20px);

	@include bp($tablet-p) {
		padding: var(--spacer-64);
	}

	@include bp($desktop) {
		padding: var(--spacer-128);
	}

}