.breadcrumb {
  >.breadcrumb-item {
    a {
      color: $breadcrumbs;
    }
    color: $breadcrumbs;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-weight: 600;
    display: inline-flex;
    align-items: baseline;
    @include font-size(16);
    svg {
      path {
        fill: $breadcrumbs !important;
      }
    }
    &:last-of-type:not(.breadcrumb-item--first) {
      font-weight: 400;
    }
  }
  .breadcrumb-item+.breadcrumb-item:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 6 10' width='6' height='10'%3E%3Cswitch%3E%3Cg%3E%3Cpath fill='%23575757' d='M.25 9.78a.8.8 0 0 1-.24-.56c0-.21.09-.4.24-.54L4.02 5 .23 1.35A.67.67 0 0 1 0 .79C0 .6.1.4.25.24A.71.71 0 0 1 .81 0c.21 0 .41.1.56.24l4.39 4.24a.8.8 0 0 1 .17.24.7.7 0 0 1 0 .6.8.8 0 0 1-.17.24L1.4 9.78a.76.76 0 0 1-.58.22.88.88 0 0 1-.57-.22z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
    width: 6px;
    height: 10px;
    background-repeat: no-repeat;
    position: relative;
    left: -3px;
  }
}
