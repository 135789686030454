@use "sass:math";

.sidebar {
  .sidebar__inner {
    margin: 1rem 0;
    padding: 1.125rem 0;
  }
}

.sidebar__inner {
  position: relative;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    background-image: $svg-vsep-02-gray;
    background-repeat: no-repeat repeat;
    left: -3rem;
  }
  p {
    margin-bottom: 0;
  }
  >.sidebar-content.sidebar-content__heading {
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
}

.sidebar-content__heading {
  padding: 0.9375rem 0;
  border-bottom: 2px solid $dark-item-border-color;
  margin-bottom: 0;
  margin-top: 4.5rem;
}

.sidebar-content__menu-item {
  @include font-size(18);
  font-weight: 500;
  line-height: math.div(20, 18);
  letter-spacing: 0.02em;
  padding: 0.9375rem 0;
  color: $breadcrumbs;
  svg {
    min-width: 12px;
    display: block;
    width: 12px;
    margin-left: 0.5rem;
    path,
    switch {
      fill: $breadcrumbs !important;
    }
  }
  &:active,
  &:focus {
    color: $primary;
    svg {
      path,
      switch {
        fill: $primary !important;
      }
    }
  }
  &:hover {
    color: $primary-dark;
    svg {
      path,
      switch {
        fill: $primary-dark !important;
      }
    }
    &::after {
      background-image: $svg-hsep-02-primary;
    }
  }
  position: relative;
  transition: all 0.125s $transition-main;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: $svg-hsep-02-gray;
    background-repeat: repeat no-repeat;
    bottom: 0;
    transition: all 0.125s $transition-main;
  }
}

.sidebar-content__text {
  @include font-size(18);
  font-weight: 500;
  line-height: math.div(20, 18);
  letter-spacing: 0.02em;
  padding: 0.9375rem 0;
  color: $breadcrumbs;
  position: relative;
  &::after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: $svg-hsep-02-gray;
    background-repeat: repeat no-repeat;
    bottom: 0;
  }
}

.sidebar-content__icon-text {
  svg {
    margin-right: 1.125rem;
    display: inline-block;
    min-width: 22px;
    width: 22px;
    height: auto;
    switch,
    path {
      fill: $primary;
    }
  }
}
