.site-footer {
  background-color: $primary-darker;
  background-image: $svg-swoosh-01-dark-xl;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 0.325rem;
  background-size: 160% 16rem;
  @include bp($md) {
    background-size: 135% 16rem;
  }
  @include bp($lg) {
    background-size: 100% 10rem;
  }
}

.footer-text-column {
  flex-flow: column;
  min-height: 5rem;
  justify-content: space-around;
  .sep-vertical {
    display: none !important;
  }
  @include bp($sm) {
    flex-flow: row;
    min-height: inherit;
    justify-content: inherit;
    .sep-vertical {
      display: inline-block !important;
    }
  }
}
