/*------------------------------------*\
	# SITE HEADER
\*------------------------------------*/

.site-header {
	position: relative;
	z-index: $zindex-dropdown + 1;
	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
}
