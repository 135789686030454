/*------------------------------------*\
	# COLOURS
\*------------------------------------*/

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
///
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
///
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

/* = MAP COLLECT (used for merging multiple maps)
https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------------- */

@function map-collect($maps...) {
	$collection: ();

	@each $map in $maps {
		$collection: map-merge($collection, $map);
	}

	@return $collection;

}

// Color system

// Document colors

// #CEE3E2 // #001D34
// #0C5A65 // #203345
// #5493BD // #334A5C
// #005A65 // #667785
// #2C7B80 // #7F8D99
// #84B6A0 // #99A4AD
// #C1DACF // #CCD1D6
// #F2F8F5 // #E5E8EA
// #F2F3F4

$white:    #fff !default;
$gray:     #ACA593 !default; // Custom
$gray-100: #f9fafa !default; // Custom
$gray-200: #f2f6fa !default; // Custom
$gray-300: #ebf1f8 !default; // Custom
$gray-400: #d5e0efeb !default; // Custom
$gray-500: #F2F2F2 !default; // Custom
$gray-600: #F2F3F4 !default;
$gray-700: #55595c !default;
$gray-800: #343a40 !default;
$gray-900: #2C2A26 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
	(
	"gray": $gray,
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900
	),
	$grays
);

$blue:    #33a4d5 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e16481 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #e3d200 !default;
$green:   #609c40 !default;
$teal:    #0d8080 !default;
$cyan:    #0D9798 !default;
$gray:      $gray-600 !default;
$gray-dark: $gray-900 !default;

$btn-border-color: #6A7784 !default;

// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray:     #ACA593 !default; // Custom
$gray-100: #f9fafa !default; // Custom
$gray-200: #f2f6fa !default; // Custom
$gray-300: #ebf1f8 !default; // Custom
$gray-400: #d5e0ef !default; // Custom
$gray-500: #F2F2F2 !default; // Custom
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2C2A26 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    #33a4d5 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e16481 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #e3d200 !default;
$green:   #609c40 !default;
$teal:    #0d8080 !default;
$cyan:    #0D9798 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       #faf4e6,
  "gray-dark":  #aca593
) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;

// fusv-disable
$blue-100: tint($blue, 80%) !default;
$blue-200: tint($blue, 60%) !default;
$blue-300: tint($blue, 40%) !default;
$blue-400: tint($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade($blue, 20%) !default;
$blue-700: shade($blue, 40%) !default;
$blue-800: shade($blue, 60%) !default;
$blue-900: shade($blue, 80%) !default;

$indigo-100: tint($indigo, 80%) !default;
$indigo-200: tint($indigo, 60%) !default;
$indigo-300: tint($indigo, 40%) !default;
$indigo-400: tint($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade($indigo, 20%) !default;
$indigo-700: shade($indigo, 40%) !default;
$indigo-800: shade($indigo, 60%) !default;
$indigo-900: shade($indigo, 80%) !default;

$purple-100: tint($purple, 80%) !default;
$purple-200: tint($purple, 60%) !default;
$purple-300: tint($purple, 40%) !default;
$purple-400: tint($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade($purple, 20%) !default;
$purple-700: shade($purple, 40%) !default;
$purple-800: shade($purple, 60%) !default;
$purple-900: shade($purple, 80%) !default;

$pink-100: tint($pink, 80%) !default;
$pink-200: tint($pink, 60%) !default;
$pink-300: tint($pink, 40%) !default;
$pink-400: tint($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade($pink, 20%) !default;
$pink-700: shade($pink, 40%) !default;
$pink-800: shade($pink, 60%) !default;
$pink-900: shade($pink, 80%) !default;

$red-100: tint($red, 80%) !default;
$red-200: tint($red, 60%) !default;
$red-300: tint($red, 40%) !default;
$red-400: tint($red, 20%) !default;
$red-500: $red !default;
$red-600: shade($red, 20%) !default;
$red-700: shade($red, 40%) !default;
$red-800: shade($red, 60%) !default;
$red-900: shade($red, 80%) !default;

$orange-100: tint($orange, 80%) !default;
$orange-200: tint($orange, 60%) !default;
$orange-300: tint($orange, 40%) !default;
$orange-400: tint($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade($orange, 20%) !default;
$orange-700: shade($orange, 40%) !default;
$orange-800: shade($orange, 60%) !default;
$orange-900: shade($orange, 80%) !default;

$yellow-100: tint($yellow, 80%) !default;
$yellow-200: tint($yellow, 60%) !default;
$yellow-300: tint($yellow, 40%) !default;
$yellow-400: tint($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade($yellow, 20%) !default;
$yellow-700: shade($yellow, 40%) !default;
$yellow-800: shade($yellow, 60%) !default;
$yellow-900: shade($yellow, 80%) !default;

$green-100: tint($green, 80%) !default;
$green-200: tint($green, 60%) !default;
$green-300: tint($green, 40%) !default;
$green-400: tint($green, 20%) !default;
$green-500: $green !default;
$green-600: shade($green, 20%) !default;
$green-700: shade($green, 40%) !default;
$green-800: shade($green, 60%) !default;
$green-900: shade($green, 80%) !default;

$teal-100: tint($teal, 80%) !default;
$teal-200: tint($teal, 60%) !default;
$teal-300: tint($teal, 40%) !default;
$teal-400: tint($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade($teal, 20%) !default;
$teal-700: shade($teal, 40%) !default;
$teal-800: shade($teal, 60%) !default;
$teal-900: shade($teal, 80%) !default;

$cyan-100: tint($cyan, 80%) !default;
$cyan-200: tint($cyan, 60%) !default;
$cyan-300: tint($cyan, 40%) !default;
$cyan-400: tint($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade($cyan, 20%) !default;
$cyan-700: shade($cyan, 40%) !default;
$cyan-800: shade($cyan, 60%) !default;
$cyan-900: shade($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
) !default;

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$primary:       #0C9798 !default;
$secondary:     #e16481 !default;
$success:       #609c40 !default;
$info:          #33a4d5 !default;
$warning:       #e36900 !default;
$danger:        #c70130 !default;
$light:         #faf4e6 !default;
$dark:          #084d4d !default;
$accent:        #33a4d5 !default;
$accent-100:    #4ebde5 !default;
$alert:         #0c9798 !default;

// #c70130    // danger
// #e36900    // warning
// #e16481    // secondary
// #cec11c    // info
// #609c40    // success
// #0c9798    // accent

// scss-docs-end theme-color-variables
$emergency-heading: #D3E8E8;
$primary-dark: #0D8080 !default;
$primary-darker: #0B6666 !default;
$primary-darkest: #084D4D !default;
$primary-light: #CDE9EA !default;
$primary-lighter: #D3E8E8 !default;
$primary-lightest: #E9F3F5 !default;

$secondary-dark: #C65872 !default;
$secondary-darker: #C75872 !default;
$secondary-darkest: tint($secondary, 60%) !default;

$secondary-light: #E7839A !default;
$secondary-lighter: #F3C1CC !default;
$secondary-lightest: #F9E0E6 !default;

$lighter: #FEFCF6 !default;
$breadcrumbs: #575757 !default;
$submenu-border: #55B7B7 !default;
$current-events: #FBF4E4 !default;
$dark-item-border-color: #D4D0C4 !default;

$accent-light: #4EBDE5 !default;
$accent-lighter: #D5EDF7 !default;
$accent-lightest: tint($accent, 60%) !default;

$accent-dark: shade($accent, 20%) !default;
$accent-darker: shade($accent, 40%) !default;
$accent-darkest: shade($accent, 60%) !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'accent': $accent,
  'yellow': $yellow,
  'alert': $alert,
  'lighter': $lighter,
  'green': #609C40
) !default;
// scss-docs-end theme-colors-map


/* = COLOR DEFINITION - BASE PALETTE
Setup your colors in a map
----------------------------------------------- */
$card-header-bg: #b4dceb;
$color-vars-common: (

	/* = COMMON COLORS
	..................................*/

	white:    $white,
	gray-100: $gray-100,
	gray-200: $gray-200,
	gray-300: $gray-300,
	gray-400: #aad0d0,
	gray-500: $gray-500,
	gray-600: $gray-600,
	gray-700: $gray-700,
	gray-800: $gray-800,
	gray-900: $gray-900,

	black:    $black,
	blue:     $blue,
	indigo:   $indigo,
	purple:   $purple,
	pink:     $pink,
	red:      $red,
	orange:   $orange,
	yellow:   $yellow,
	green:    $green,
	teal:     $teal,
	cyan:     $cyan,

);

$color-vars-theme: (

	/* = THEME COLORS
	..................................*/

  primary-dark:     $primary-dark,
  primary-darker:   $primary-darker,
  primary-darkest:  $primary-darkest,
  primary-light:    $primary-light,
  primary-lighter:  $primary-lighter,
  primary-lightest: $primary-lightest,
  secondary-dark:     $secondary-dark,
  secondary-darker:   $secondary-darker,
  secondary-darkest:  $secondary-darkest,
  secondary-light:    $secondary-light,
  secondary-lighter:  $secondary-lighter,
  secondary-lightest: $secondary-lightest,
	accent-2:         $accent-100,
  current-events:    $current-events

);

$color-vars-state: (

	/* = STATE COLORS
	..................................*/

	positive:   mediumseagreen,
	negative:   firebrick,

);

$color-vars-social: (

	/* = SOCIAL NETWORK COLORS
	..................................*/

	facebook:   #3b5998,
	twitter:    #5ea9dd,
	instagram:  #683d2f,

);


/* = COMBINE BASE PALETTE
..................................*/

$color-vars-palette: map-collect(
  $theme-colors,
	$color-vars-common,
	$color-vars-theme,
	$color-vars-state,
	$color-vars-social,
);


/* = CREATE COLOR FUNCTION - PALETTE

!![[ For use inside this file only ]]
----------------------------------------------- */

@function color-palette__($color) {
	@return map-get($color-vars-palette, $color);
}





/* = COLOR DEFINITION - APPLIED
Setup your colors in a map
----------------------------------------------- */

$color-vars-applied-text: (

	/* = TEXT COLORS
	..................................*/

	text-main:            color-palette__(black),
	link:                 color-palette__(primary),
	link-visited:         darken( color-palette__(primary), 25% ),
	link-hover:           darken( color-palette__(primary), 20% ),

);

$color-vars-applied: map-collect(
	$color-vars-applied-text,
);


/* = COMBINE PALETTE and APPLIED COLOR MAPS
----------------------------------------------- */

$color-vars-all: map-collect($color-vars-palette, $color-vars-applied);


/* = CREATE COLOR FUNCTION
-----------------------------------------------

Example Usage
-------------

body {
	background-color: color__(primary-f2f);
}

----------------------------------------------- */

@function color__($color) {
	@return map-get($color-vars-all, $color);
}





