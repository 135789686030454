.footer-current-events {
  padding-bottom: 70px;
}

.footer-current-events__row {
  >.col:nth-child(4) {
    display: none !important;
    @include bp($sm) {
      display: block !important;
    }
    @include bp($lg) {
      display: none !important;
    }
  }
}
