.fp-featured-items__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fp-featured-items__cards {
  >.col:nth-child(2) {
    display: none !important;
    @include bp($sm) {
      display: block !important;
    }
    @include bp($lg) {
      display: none !important;
    }
  }
}

.fp-featured-items,
.fp-activities__cards {
  .card-footer {
    .d-inline-flex {
      transition: all 0.125s $transition-main;
      >svg {
        transform: translate3d(0, 0, 0);
        transition: all 0.125s $transition-main;
      }
    }
  }
  .card {
    &:hover,
    &:active,
    &:focus {
      .card-footer {
        .d-inline-flex {
          color: hsl(180 85% 27% / 1);
          >svg {
            transform: translate3d(3px, 0, 0);
          }
        }
      }
    }
  }
}

.link-read-more {
  >svg {
    transform: translate3d(0, 0, 0);
    transition: all 0.125s $transition-main;
  }
  &:hover,
  &:active,
  &:focus {
    >svg {
      transform: translate3d(3px, 0, 0);
    }
  }
}
