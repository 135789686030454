@use "sass:math";

.content-block {

}

.blockquote-link {
  display: block;
  width: 100%;
  margin: 0;
    >p {
    margin: 0;
    >a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-width: 17.875rem;
      @include bp($md) {
        width: 75%;
      }
      padding: 0.5rem 2rem 0.5rem 0;
      background-position: center right;
      background-repeat: no-repeat;
      margin: 0;
      font-weight: 700;
      line-height: math.div(24, 16);
      letter-spacing: 0.03em;
      color: $primary;
      transition: color 0.125s ease-in-out, border-color 0.125s ease-in-out;
      background-image: $svg-download-01;
      border-bottom: 1px solid $primary;
      svg {
        path,
        switch {
          transition: fill 0.125s ease-in-out;
          fill: $primary !important;
        }
      }
      &:hover,
      &:active,
      &:focus {
        color: $primary-dark;
        svg {
          path,
          switch {
            fill: $primary-dark !important;
          }
        }
      }
    }
  }
  ul {
    >li {
      &::marker {
        color: $primary;
        content: " ▪  ";
      }
    }
  }
  table {
    ul {
      >li {
        &::marker {
          color: $accent;
          content: " ▪  ";
        }
      }
    }
  }
}
