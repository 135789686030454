@use "sass:math";

.alert {
  &.alert-light,
  &.alert-warning {
    border-color: #fff !important;
  }
  &.alert-dismissible .btn-close {
    opacity: 0.75;
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}

.alert-primary,
.alert-secondary,
.alert-success,
.alert-info,
.alert-warning,
.alert-danger,
.alert-dark,
.alert-accent,
.alert-alert,
.alert-green {
  color: #fff !important;
  &.alert-dismissible .btn-close {
    padding: 1.85rem 1.875rem;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='28' height='28' viewBox='0 0 35 35'%3E%3Cpath fill='%23FFFFFF' d='M0 32.81 15.31 17.5 0 2.19 2.19 0 17.5 15.31 32.81 0 35 2.19 19.69 17.5l15.3 15.31L32.82 35 17.5 19.69 2.19 35 0 32.81z'/%3E%3C/svg%3E") 50%/16px auto no-repeat;
  }
  a {
    color: #fff !important;
  }
  svg {
    switch,
    path {
      fill: #fff !important;
    }
  }
}

.alert-light,
.alert-yellow {
  color: #000 !important;
  &.alert-dismissible .btn-close {
    padding: 1.85rem 1.875rem;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='28' height='28' viewBox='0 0 35 35'%3E%3Cpath fill='%23000000' d='M0 32.81 15.31 17.5 0 2.19 2.19 0 17.5 15.31 32.81 0 35 2.19 19.69 17.5l15.3 15.31L32.82 35 17.5 19.69 2.19 35 0 32.81z'/%3E%3C/svg%3E") 50%/16px auto no-repeat
  }
  a {
    color: #000 !important;
  }
  svg {
    switch,
    path {
      fill: #000 !important;
    }
  }
}

.alert__icon--wrapper {
  svg {
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 4.5rem;
    margin-top: 0.325rem;
  }
}

.alert__content--wrapper {
  @include font-size(18);
  letter-spacing: 0.03em;
  line-height: math.div(21, 18);
  font-weight: 500;
  b,
  strong {
    font-weight: 700;
    letter-spacing: 0.1em;
  }
}

.vsep {
  font-weight: 400;
}
