/*------------------------------------*\
	# ACCESSIBILITY
\*------------------------------------*/


// Text meant only for screen readers
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:hover,
	&:active,
	&:focus {
		background-color: color__(background-screen);
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: color__(text-screen);
		display: block;
		@include font-size(14);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar */
	}
}