@use "sass:math";

.two-col {
  .row {
    >[class^=col] {
      // display: flex;
      // flex-flow: column;
      // align-items: flex-start;
      // justify-content: center;
      >img {
        aspect-ratio: 6 / 5;
        height: 100%;
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.fw-two-col-img {
  opacity: .6;
  max-height: 45rem;
  object-fit: cover;
  aspect-ratio: 700 / 720;
}

.fw-two-col__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fw-two-col-link__wrapper {
  display: flex;
  border: 1px solid currentColor;
  background: rgba(#fafafa, 0.75);
  border-radius: 50rem;
}

.fw-two-col-link {
  padding: 1.125rem 2.8125rem;
  text-align: center;
  font-weight: 700;
  @include font-size(16);
  letter-spacing: 0.05em;
  line-height: math.div(21, 16);
}

.two-col-images__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16 / 12;
  @include bp($sm) {
    aspect-ratio: 600 / 500;
  }
}

.two-col-image-left__table--wrapper {
  >.two-col-image-left__table--row {
    border-top: 1px solid $dark-item-border-color;
    &:last-child {
      border-bottom: 1px solid $dark-item-border-color;
    }
  }
}

.two-col-image-right__table--wrapper {
  >.two-col-image-right__table--row {
    border-top: 1px solid $dark-item-border-color;
    &:last-child {
      border-bottom: 1px solid $dark-item-border-color;
    }
  }
}

.two-col-image-left__table--content,
.two-col-image-right__table--content {
  p {
    margin: 0;
  }
}

.two-col-image-left__table--icon,
.two-col-image-right__table--icon {
  svg {
    width: 1rem;
    display: block;
    margin: 0 auto;
    switch,
    path {
      fill: $primary !important;
    }
  }
}
