@use "sass:math";

$primary-emergency: #0B6C6C;

.page-template-page-emergency,
.emergency {
  .page-header,
  .site-main {
    background: $primary-emergency;
    color: $light;
  }
  .breadcrumb {
    >.breadcrumb-item,
    >.breadcrumb-item a {
      color: $primary-lightest;
    }
    .breadcrumb-item+.breadcrumb-item:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 6 10' width='6' height='10'%3E%3Cswitch%3E%3Cg%3E%3Cpath fill='%23e9f3f5' d='M.25 9.78a.8.8 0 0 1-.24-.56c0-.21.09-.4.24-.54L4.02 5 .23 1.35A.67.67 0 0 1 0 .79C0 .6.1.4.25.24A.71.71 0 0 1 .81 0c.21 0 .41.1.56.24l4.39 4.24a.8.8 0 0 1 .17.24.7.7 0 0 1 0 .6.8.8 0 0 1-.17.24L1.4 9.78a.76.76 0 0 1-.58.22.88.88 0 0 1-.57-.22z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E%0A");
    }
  }
  .sidebar * {
    color:#FEFCF6 !important;
    svg {
      switch,
      path {
        fill: #FEFCF6 !important;
      }
    }
  }

}

.emergency__subheading {
  @include font-size(22);
  font-weight: 700;
  line-height: math.div(26, 22);
  letter-spacing: 0.03em;
  color: $emergency-heading;
  svg {
    width: 23px;
    display: inline-block;
    margin-right: 0.75rem;
  }
}

.weather-list {
  .bs {
    color: $emergency-heading !important;
  }
}
