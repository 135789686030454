/*------------------------------------*\
  # = IMAGE COVER with FALLBACK
  - Source: https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
\*------------------------------------*/


.image-cover {
  overflow: hidden;
}

.image-cover > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;

  display: block;
  line-height: 1;
}

.ie-compat-object-fit {
  background-size: cover;
  background-position: center center;

  > img {
    visibility: hidden;
  }

}