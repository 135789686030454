@use "sass:math";

.nav-topbar {
  @include bp(0 $nv) {
    padding: .3125rem 0.5rem;
  }
  .btn-pill {
    @include font-size(16);
    line-height: math.div(27, 18);
    letter-spacing: 0.05em;
    font-weight: 600;
    @include bp($md) {
      #inputGroup-search__navbar {
        width: 24px;
      }
    }
  }
  .nav-item {
    &::before {
      display: block;
      position: absolute;
      width: 1px;
      height: calc(100% - 20px);
      background: #085b5c;
      content: " ";
      top: 10px;
    }
    &:first-of-type {
      &::before {
        display: none;
      }
      .nav-link {
        padding-left: 0.5rem;
        @include bp($nv) {
          padding-left: 1rem;
        }
      }
    }
    .nav-link {
      @include font-size(16);
      line-height: math.div(21, 16);
      letter-spacing: 0.05em;
      font-weight: 700;
      color: $light;
      padding-left: 0.4325rem;
      padding-right: 0.4325rem;
      @include bp($md) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
      @include bp($lg) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: $light;
        }
      }
    }
  }
}

.nav-topbar__form {
  display: none;
  @include bp(942px) {
    display: flex;
  }
}

.nav-topbar__left {
  @include bp(0 $nv) {
    width: 100%;
  }
  .nav-item {
    cursor: pointer;
    transition: color 0.1s linear;
    >.nav-link {
      transition: color 0.1s linear;
      display: inline-flex;
      flex-flow: row;
      @include bp(0 $nv) {
        @include font-size(15);
      }
      @include bp(340px $md) {
        padding-inline: 1rem;
      }
      >svg,
      >.icon {
        margin-right: 12px;
        width: 22px;
        height: 22px;
        display: inline-block;
      }
      >svg {
        path {
          transition: fill 0.1s linear;
        }
      }
      >.icon {
        mix-blend-mode: normal;
        filter: brightness(3.5);
        transform: scale(1.625);
        transition: all 0.1s linear;
      }
    }
  }
}

#inputGroup-search__navbar {
  svg {
    width: 24px;
  }
}

.nav-item {
  &.nav-item__weather {
    >.nav-item {
      display: inline-flex;
      align-items: center;
      @include bp(0 $nv) {
        @include font-size(12);
      }
    }
  }
}

.nav-topbar__left {
  .nav-item--hoverable {
  .nav-link {
    position: relative;
      &:after {
        width: calc(100% - 2rem - 2px);
        left: 1rem;
        bottom: -5px;
      }
    }
  }
}
