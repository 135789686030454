.svg-swoosh {
  overflow: visible;
  max-width: 100%;
  display: block;
  height: auto;
  position: relative;
  top: 2px;
  &.svg-swoosh-02-sm {
    switch {
      transform: translateY(-30%);
    }
  }
  // &.svg-swoosh-04-xl {
  //   width: 100%;
  //   switch {
  //     transform: translateY(-100%);
  //   }
  // }
}

.page-header__swoosh {
  position: absolute;
  bottom: 0;
  width: 100%;
  // svg {
  //   width: 100%;
  //   display: block;
  //   path {
  //     fill: $lighter !important;
  //   }
  // }
}


.footer-current-events__swoosh {
  position: absolute;
  top: 0;
  right: 0;
}

.footer-info-boxes__swoosh {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  svg {
    path {
      fill: #EDA2B3 !important;
    }
  }
}

.vsep {
  &.vsep-01 {
    position: absolute;
    overflow: visible;
    display: flex;
    align-items: flex-start;
    top: -4.875rem;
    @include bp($lg) {
      top: calc((4.375rem + 32px) * -1);
    }
    svg {
      min-width: 1px;
      width: 0.06251rem;
      height: 100%;
      path {
        stroke: $gray;
      }
    }
  }
}

.svg-swoosh {
  &.svg-swoosh-04-xl {
    margin-top: -11.375rem;
    padding-bottom: 11.375rem;
    height: #{rem-calc(11.375*2)}rem;
    object-fit: cover;
    width: 100vw;
  }
}
