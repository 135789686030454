/*------------------------------------*\
	# LAYOUT SETTINGS
\*------------------------------------*/


/* = SPACERS
----------------------------------------------- */

// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,         // 4px
  2: $spacer * .5,          // 8px
  3: $spacer * .75,         // 12px
  4: $spacer * 1,           // 16px
  5: $spacer * 1.25,        // 20px
  6: $spacer * 1.5,         // 24px
  7: $spacer * 1.75,        // 28px
  8: $spacer * 2.125,       // 34px
  9: $spacer * 3,           // 48px
  10: $spacer * 3.75,       // 60px
  11: $spacer * 4.375,      // 70px
  12: $spacer * 6.5,        // 104px
  13: $spacer * 8.75,       // 140px
  14: $spacer * 10,         // 160px
  15: $spacer * 12.5,       // 200px
  16: $spacer * 15          // 240px
  ) !default;

  $container-max-widths: (
  sm: calc(100% - 40px),
  md: calc(100% - 60px),
  lg: calc(100% - 80px),
  xl: calc(100% - 100px),
  xxl: 1400px
) !default;

:root {
	@each $space_name, $space in $spacers {
		--spacer-#{""+$space_name}: #{$space};
	}
}

/* = GAPS
Used for single direction dynamic spacing instead of fixed.
@see _stack.scss
----------------------------------------------- */

$gaps: (
	gap-xxs:    0.5em,
	gap-xs:     0.75em,
	gap-sm:     1.0em,
	gap:        1.5em,
	gap-md:     1.75em,
	gap-lg:     2.0em,
	gap-xl:     2.5em,
	gap-xxl:    3.0em,
);

:root {
	@each $gap_name, $gap in $gaps {
		--#{""+$gap_name}: #{$gap};
	}
}


/**
 * Breakpoints
 * @link https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
 */

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    767.98px
@function breakpoint-end($value) {
	@return if($value and $value > 0, $value - .02, null);
}

// XS
$phone:               0;
$phone-end:             breakpoint-end(576px);
$xs:                  $phone;
$xs-end:              $phone-end;

// SM
$phone-l:             576px;
$phone-l-end:           breakpoint-end(768px);
$sm:                  $phone-l;
$sm-end:              $phone-l-end;

// MD
$tablet-p:            768px; // Portrait
$tablet-p-end:          breakpoint-end(992px);
$md:                  $tablet-p;
$md-end:              $tablet-p-end;

// LG
$tablet:              992px; // Landscape
$tablet-end:            breakpoint-end(1200px);
$lg:                  $tablet;
$lg-end:              $tablet-end;

// XL
$desktop:             1200px;
$desktop-end:           breakpoint-end(1400px);
$xl:                  $desktop;
$xl-end:              $desktop-end;

// XXL
$desktop-m:           1400px;
$desktop-m-end:         breakpoint-end(1600px);
$xxl:                  $desktop-m;
$xxl-end:              $desktop-m-end;

// XXXL
$desktop-l:           1600px;
$xxxl:                $desktop-l;

// Grid columns
// Set the number of columns and specify the width of the gutters.

// $grid-columns:                48 !default;
// $grid-gutter-width:           0 !default;
// $grid-row-columns:            6 !default;

$general-max-widths: (
	640: 640px,
	64ch: 64ch,
);