
$table-typography-selector: '' !default;

table.dataTable#{$table-typography-selector} {
	&.nowrap {
		th, td {
			white-space: nowrap;
		}
	}

	th,
	td {
		box-sizing: border-box;

		&.dt-left {
			text-align: left;
		}

		&.dt-center {
			text-align: center;
		}

		&.dt-right {
			text-align: right;
		}

		&.dt-justify {
			text-align: justify;
		}

		&.dt-nowrap {
			white-space: nowrap;
		}

		&.dt-empty {
			text-align: center;
			vertical-align: top;
		}

		&.dt-type-numeric,
		&.dt-type-date {
			text-align: right;
		}
	}

	thead,
	tfoot {
		th,
		td {
			text-align: left;

			&.dt-head-left {
				text-align: left;
			}
			
			&.dt-head-center{
				text-align: center;
			}

			&.dt-head-right {
				text-align: right;
			}

			&.dt-head-justify {
				text-align: justify;
			}

			&.dt-head-nowrap {
				white-space: nowrap;
			}
		}
	}

	tbody {
		th,
		td {
			&.dt-body-left {
				text-align: left;
			}

			&.dt-body-center {
				text-align: center;
			}

			&.dt-body-right {
				text-align: right;
			}

			&.dt-body-justify {
				text-align: justify;
			}

			&.dt-body-nowrap {
				white-space: nowrap;
			}
		}
	}
}
