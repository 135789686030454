@use "sass:math";

.footer__middle-wrapper {
  padding-bottom: 3.5625rem;
  @include bp($md) {
    padding-bottom: 4.5625rem;
  }
  @include bp($lg) {
    padding-bottom: 7.5625rem;
  }
  @include bp($xl) {
    padding-bottom: 13.5625rem;
  }
  h4 {
    @include font-size(24);
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: math.div(33, 24);
  }
  h5 {
    @include font-size(22);
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: math.div(34, 22);
  }
  p {
    @include font-size(16);
    line-height: 1.5;
    letter-spacing: 0.03em;
    font-weight: 400;
    color: $lighter;
  }
}

.btn-footer-social-link {
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  border: 1px solid $lighter;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: #99d1d124;
    color: #faf4e6;
    border-color: inherit;
  }
}

.footer__middle-col {
  &.footer__middle-col--0 {
    order: 0;
  }
  &.footer__middle-col--1 {
    order: 2;
  }
  &.footer__middle-col--2 {
    order: 1;
    padding: 2rem 0;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;
    margin-bottom: 2rem;
  }
  @include bp($sm) {
    &.footer__middle-col--1 {
      order: 1;
    }
    &.footer__middle-col--2 {
      order: 2;
      padding: inherit;
      margin-bottom: inherit;
      border: inherit;
    }
  }
}
