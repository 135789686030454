/*------------------------------------*\
	# SHAME, SHAME, SHAME!
	- Just kidding it's just a good place to dump things
	when you don't know here they go yet.
\*------------------------------------*/

@use "sass:math";

@use "sass:map";

a {
  transition: all 0.125s $transition-main;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-self-start {
  justify-self: start !important;
}

.form__search {
  // width: 28.125rem;
  // max-width: 100%;
  max-width: 100%;
  width: 62rem;
  padding: 0 1.5rem;
  @include bp($sm) {
    padding: 0 3rem;
  }
  @include bp($md) {
    padding: 0 5rem;
  }
  @include bp($lg) {
    padding: 0 7rem;
  }
}

body {
  overflow-x: hidden;

  code {
    font-family: "FiraCodeOperator Medium", consolas, monospace;
    font-size: 18px;
    line-height: 1.5;
    background: rgba(#fff, 0.925);
    display: block;
  }
}

.mx-n5 {
  margin-left: map.get($spacers, 5) * -1 !important;
  margin-right: map.get($spacers, 5) * -1 !important;
}

.card-img-top,
.card-header img,
.fp-featured-items__img,
.footer-instagram__img {
  position: relative;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: inherit;
  //   height: inherit;
  //   background-image: url(../assets/img/svg-circle-text-01.svg);
  //   background-size: 78px 70px;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   color: transparent;
  // }
  // background-image: url(../assets/img/svg-circle-text-01.svg);
  // background-size: 78px 70px;
  // background-repeat: no-repeat;
  // background-position: center center;
  // color: transparent;
}

.card-img-placeholder {

}

.site {
  background: $body-bg;
}

*::selection {
  background: #0e6969e8;
  color: #fff;
}

// .has-swoosh-03-xl {
//   svg {
//     * {
//       fill: $lighter;
//     }
//   }
// }

.has-swoosh-04-xl {
  margin-top: 182px !important;
}

.content {
  @include font-size(16);
  line-height: math.div(22, 16);
  letter-spacing: 0.02em;
  font-weight: 400;
}

.svg {
  &.svg-circle-text-01 {
    right: 0;
    top: calc(-4.375rem - 44px);
    @include bp($xl) {
      top: calc(-4.375rem - 61px);
    }
    >svg {
      display: none;
      height: auto;
      width: 88px;
      @include bp($lg) {
        display: block;
      }
      @include bp($xl) {
        width: 122px;
      }
    }
  }
}

.svg-hsep-01-full {
  background-image: $svg-hsep-01-gray;
  background-repeat: repeat no-repeat;
  height: 2px;
  width: calc(100% - 34px);
  display: block;
}

.svg-hsep-01-full-hover {
  background-image: $svg-hsep-01-gray;
  background-repeat: repeat no-repeat;
  height: 2px;
  width: calc(100% - 34px);
  display: block;
}

hr {
  background-image: $svg-hsep-03;
  background-repeat: repeat no-repeat;
  height: 2px;
  width: 100%;
  display: block;
  opacity: 1;
}

.table-group-divider {
  border-top-color: $primary;
}

.table {
  >thead>tr>th {
    color: $primary;
    letter-spacing: 0.03em;
    font-weight: 600;
  }
}

.table-wrapper {
  .col-12 {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.template-fw {
  >.container {
    padding-left: 1.0625rem !important;
    padding-right: 1.0625rem !important;
    @include bp($xl) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.text-underline {
  text-decoration: underline !important;
}


.container-news {
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    background-image: $svg-swoosh-04-bg;
    background-repeat: no-repeat;
    height: 75%;
    position: absolute;
    top: 25%;
    width: 100%;
    z-index: 0;
    background-size: 100%;
    background-color: #fbf4e4;
  }
}

span.page-link.disabled.dots {
  color: #ccc;
}

.date-italic {
  font-weight: 500;
  line-height: math.div(20, 18);
  font-style: italic;
  letter-spacing: 0.02em;
  color: #575757;
}

.link-read-more {
  color: #575757;

  svg {
    width: 13px;
    height: auto;
    margin-left: 10px;
    display: inline-block
  }
}

.flexi-alert__col {
  p {
    margin: 0;
  }
}


body {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    b, strong {
      font-weight: 800;
    }
  }
  .accordion-body {
    @include bp(0 $sm) {
      tr {
        display: flex;
        flex-flow: column;
      }
    }
  }
}

.sidebar {
  .sidebar__inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.lh-8 {
  line-height: 8px;
}

.lh-9 {
  line-height: 9px;
}

.lh-10 {
  line-height: 10px;
}

.lh-11 {
  line-height: 11px;
}

.lh-12 {
  line-height: 12px;
}

.lh-13 {
  line-height: 13px;
}

.lh-14 {
  line-height: 14px;
}

.lh-15 {
  line-height: 15px;
}

.lh-16 {
  line-height: 16px;
}

.lh-17 {
  line-height: 17px;
}

.lh-18 {
  line-height: 18px;
}

.lh-19 {
  line-height: 19px;
}

.lh-20 {
  line-height: 20px;
}

.lh-21 {
  line-height: 21px;
}

.lh-22 {
  line-height: 22px;
}

.lh-23 {
  line-height: 23px;
}

.lh-24 {
  line-height: 24px;
}

.lh-25 {
  line-height: 25px;
}

.lh-26 {
  line-height: 26px;
}

.lh-27 {
  line-height: 27px;
}

.lh-28 {
  line-height: 28px;
}

.lh-29 {
  line-height: 29px;
}

.lh-30 {
  line-height: 30px;
}

.lh-31 {
  line-height: 31px;
}

.lh-32 {
  line-height: 32px;
}

.lh-33 {
  line-height: 33px;
}

.lh-34 {
  line-height: 34px;
}

.lh-35 {
  line-height: 35px;
}

.lh-36 {
  line-height: 36px;
}

.lh-37 {
  line-height: 37px;
}

.lh-38 {
  line-height: 38px;
}

.lh-39 {
  line-height: 39px;
}

.lh-40 {
  line-height: 40px;
}

.lh-41 {
  line-height: 41px;
}

.lh-42 {
  line-height: 42px;
}

.lh-43 {
  line-height: 43px;
}

.lh-44 {
  line-height: 44px;
}

.lh-45 {
  line-height: 45px;
}

.lh-46 {
  line-height: 46px;
}

.lh-47 {
  line-height: 47px;
}

.lh-48 {
  line-height: 48px;
}

.gform_fileupload_multifile .gform_drop_area {
  min-height: 158px;
}

.fp-activities__cards {
  align-items: flex-start;
  >.col {
    &:nth-last-child(1) {
      display: block !important;
      @include bp($sm) {
        display: none !important;
      }
      @include bp($lg) {
        display: block !important;
      }
    }
  }
}

.instagram-heading {
  font-size: 1rem;
  @include bp($xl) {
    font-size: 1.75rem;
  }
}


.c-fp-social__item,
.c-fp-social__img {
  height: 100%;
}

.swp-result-item {
  .entry-title {
    color: $primary;
  }
}

.swp-rp-page-header .page-title {
  text-align: left;
  font-size: 2rem;
  color: #212121;
}

.swp-rp-page-header {
  #searchwp-form-1 {
    border: 2px solid $primary;
  }
}

#main.site-main.swp-rp-main>div {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
#main.site-main.swp-rp-main {
.swp-search-results {
  margin-bottom: 0rem;
  max-width: 1240px;
  padding: 2rem 0.5rem;
}
}

.swp-rp-page-header {
  #searchwp-form-1 {
    border: 1px solid $primary;
  }
}

#searchwp-form-1 {
  max-width: 458px;
  background: #fff;
  border-radius: 999em !important;
  margin-bottom: 2.125rem;
  >.swp-flex--col {
    >.swp-flex--row {
      border: 0!important;
      font-size: 20px;
      color: $primary;
      border: 0;
      display: block;
      margin: 0 auto;
    }
  }
  .swp-input--search.swp-input {
    border: 0;
    font-weight: 600;
    color: $primary;
    font-size: 23px;
    background: none;
    outline: 0;
    &::placeholder {
      color: $primary;
    }
  }
}

.searchwp-form-input-container.swp-items-stretch {
  padding: 1.125rem 1rem;
  margin: 0;
}


.weather-item {
  line-height: 1;
  padding-left: 0.5rem;
  letter-spacing: 0.02em;
  >div {
    letter-spacing: 0.02em;
  }
}

.c-navbar-weathers {
  position: relative;
}

.c-navbar-weathers > .nav-link:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

.c-navbar-weathers > .nav-link {
  opacity: 0;

  animation: fade_3_weathers 15s ease-in-out infinite;
}

.c-navbar-weathers > .nav-link:nth-child(2) {
  animation: fade_3_weathers 15s ease-in-out infinite;
  animation-delay: 5s;
}

.c-navbar-weathers > .nav-link:nth-child(3) {
  animation: fade_3_weathers 15s ease-in-out infinite;
  animation-delay: 10s;
}


.c-navbar-weather__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-navbar-weather__data {
  max-height: 1.8rem;
}

.c-navbar-weather__location {
  @include font-size(9);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.130em;
}

.c-navbar-weather:hover {
  cursor: default;
}

@keyframes fade_3_weathers {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#inputGroup-search__navbar__home {
  >svg {
    width: 26px;
  }
}

.searchwp-live-search-result {
  &:hover {
    background: hsl(45 80% 98% / 1);
  }
  p {
    padding: 0;
    > a {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 0.825;
    }
  }
}

.sidebar-content__menu-item {
  justify-content: space-between;
}


.nav-item--hoverable {
  position: relative;
  >a {
    &::after {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-image: $svg-hsep-02-dark;
        background-repeat: repeat no-repeat;
        transition: all 0.125s $transition-main;
      }
    }
  &:hover,
  &:active,
  &:focus {
  >a {
    &::after {
        background-image: $svg-hsep-02-gray;
      }
    }
  }
}

.bg-light-after {
  &::after {
    content: " ";
    display: block;
    width: 100%;
    background: $light;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
  +div {
    background: $light;
    z-index: 1;
    position: relative;
    &::after {
      content: " ";
      display: block;
      width: 100%;
      background: $light;
      height: 100%;
      position: absolute;
      z-index: 0;
    }
    +div {
      background: $light;
      z-index: 2;
      position: relative;
    }
  }
}

body .searchwp-live-search-no-min-chars .spinner {
  width: 48px !important;
  height: 48px !important;
  opacity: 1 !important;
  background: url('../assets/img/svg-circle-text-01.svg') !important;
  object-position: 9999em 9999em;
  left: calc(50% - 24px)  !important;
  top: calc(50% - 24px)  !important;
  >div {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.card a[href="#"] {
  display: none !important;
}

.home {
  .vsep.vsep-01 {
    left: 50%;
  }
}

.weather-icon,
.navbar-weather-icon {
  width: 2rem;
}

.entry-content table>tbody>tr>td {
  vertical-align: middle;
  >p.p1 {
    margin-bottom: 0.375rem;
  }
}

.svg-icon-theme__night {
  color: #e4e4e4;
}

.has-swoosh__svg-swoosh-03-xl {
  width: 100%;
}

