.footer-instagram {
  >.c-fp-social__item-wrapper {
    &:nth-last-child(1) {
      display: none !important;
      @include bp($lg) {
        display: block !important;
      }
    }
  }
}

.footer-instagram__col {
  transition: background-color 0.125s $transition-main;
  background-color: $cyan;
  .footer-instagram__link {
    svg {
      transform: translate3d(0, 0, 0);
      transition: transform 0.125s $transition-main;
    }
  }
  &:hover,
  &:active,
  &:focus {
    background-color: rgba($cyan, 0.8);
    .footer-instagram__link {
      svg {
        transform: translate3d(3px, 0, 0);
      }
    }
  }
}

.footer__upper-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include bp($md) {
    padding-top: 4rem;
    padding-bottom: 2.5rem;
  }
  @include bp($lg) {
    padding-top: 5.5rem;
    padding-bottom: 4.25rem;
  }
}

.footer-instagram__img {
  height: auto;
  width: 100%;
}

.footer-instagram__link {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  line-height: 22px;
  color: $lighter;
  letter-spacing: 0.1em;
  font-weight: 700;
  svg {
    path {
      fill: $lighter;
    }
  }
}

.footer-instagram__link-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $light;
  border-radius: 50%;
}

.btn-footer-instagram-next {
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  right: -1.625rem;
  top: calc(50% - 1.625rem);
  align-items: center;
  padding: 0;
  justify-content: center;
  >svg {
    width: 8.5px !important;
    height: 14px !important;
  }
  display: none;
  @include bp($lg) {
    display: inline-flex;
  }
}
